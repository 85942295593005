import React, { useCallback } from 'react';
import { Dropdown } from '@holokit/core';
import { globalRoleToHumanReadable } from 'common/utils/permissionsAndRoles';

const GlobalRoleDropdown = ({ role, updateRole, userCurrentRole }) => {
  const pushOptionSection = useCallback(
    (options, option, section, disabled = false) => {
      const newOption = {
        children: option.children,
        caption: option.caption,
        disabled,
        onClick: () => updateRole(option.role),
        sectionId: section,
        closeAfterClick: true,
      };
      options.push(newOption);
      return options;
    },
    [updateRole]
  );

  const restrictOption = {
    children: 'Make Non-Admin',
    caption: 'Remove access.',
    role: '',
  };
  const shpOption = {
    children: 'Make Ship Admin',
    caption: 'Grant shipping access.',
    role: 'shp',
  };
  const admOption = {
    children: 'Make Admin',
    caption: 'Allows user to make changes to orgs outside their own.',
    role: 'adm',
  };
  const hdmOption = {
    children: 'Make Hyper Admin',
    caption: 'Allows user to make changes to orgs outside their own including activation.',
    role: 'hdm',
  };

  let options = pushOptionSection([], restrictOption, 1, userCurrentRole === '');

  if (role === 'adm') {
    options = pushOptionSection(options, admOption, 2, userCurrentRole === 'adm');
  }
  if (role === 'shp') {
    options = pushOptionSection(options, shpOption, 2, userCurrentRole === 'shp');
  }
  if (role === 'hdm') {
    options = pushOptionSection(options, shpOption, 2, userCurrentRole === 'shp');
    options = pushOptionSection(options, admOption, 3, userCurrentRole === 'adm');
    options = pushOptionSection(options, hdmOption, 4, userCurrentRole === 'hdm');
  }
  return (
    <Dropdown
      classes="collab-list-item__dropdown"
      dropdownText={globalRoleToHumanReadable(userCurrentRole)}
      items={options}
    />
  );
};

GlobalRoleDropdown.defaultProps = {
  userCurrentRole: '',
};

export default GlobalRoleDropdown;
