import React from 'react';
import { Panel } from '@hologram-dimension/panel';
import SearchOrgsForm from '../SearchOrgsForm';

const CreatingOrgPanel = (props) => {
  const { isActive, groupIsNew, groupName, isComplete, panelRef, onOrgSelect, group } = props;
  return (
    <Panel header="Orgs">
      <SearchOrgsForm
        groupMembers={group?.users}
        groupIsNew={groupIsNew}
        groupName={groupName}
        isActive={isActive}
        isComplete={isComplete}
        onOrgSelect={onOrgSelect}
        panelRef={panelRef}
        group={group}
        header={`Add an org to ${groupName}`}
      />
    </Panel>
  );
};

export default CreatingOrgPanel;
