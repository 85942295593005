import * as actionTypes from './actionTypes';

export const updateFlagsForOrgs = (flagName, isRollingOut) => ({
  type: actionTypes.UPDATE_FLAGS_FOR_ORGS_REQUEST,
  requestType: 'PUT',
  successType: actionTypes.UPDATE_FLAGS_FOR_ORGS_SUCCESS,
  errorType: actionTypes.UPDATE_FLAGS_FOR_ORGS_ERROR,
  url: '/releaseflags/rollout',
  payload: {
    name: flagName,
    state: isRollingOut ? 'on' : 'off',
    percentageRollout: 25, // This has to be hardcoded for now as per our backend
  },
});
