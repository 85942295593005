import * as actionTypes from './actionTypes';

export const searchUserOrgsByEmail = (email) => ({
  type: actionTypes.GET_ORGS_BY_USER_EMAIL,
  email,
});

export const searchUsersByEmail = (email) => ({
  type: actionTypes.SEARCH_USER_BY_EMAIL_REQUEST,
  requestType: 'GET',
  successType: actionTypes.SEARCH_USER_BY_EMAIL_SUCCESS,
  errorType: actionTypes.SEARCH_USER_BY_EMAIL_ERROR,
  url: `/users?email=${email}`,
});
