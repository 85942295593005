import { addCommasToNumber } from 'common/utils/numberFormatter';
import { toObjectByIds } from 'common/utils/objectFormatter';
import { createSelector } from 'reselect';
import { getCurrentDeviceById } from '../devices/index';

export const getPlans = (state) => state.entities.plans.byId;

export const getPlansNoGroupZero = createSelector(getPlans, (plans) =>
  toObjectByIds(Object.values(plans).filter((plan) => plan.id !== 0))
);

const getCurrentPlanById = (state, props) => {
  const planId = props.match.params.id;
  return getPlans(state)[planId];
};

export const getFormattedTiers = createSelector(getCurrentPlanById, (plan) => {
  if (plan) {
    const { tiers } = plan;
    return Object.entries(tiers).reduce((acc, [tierName, tier]) => {
      if (tierName !== 'BASE') {
        const { zones } = tier;
        const zoneData = Object.values(zones)[0];
        acc[tierName.split('DV')[0]] = {
          amount: zoneData.amount,
          overage: zoneData.overage,
          sms: zoneData.sms,
          tier: tier.devicecount,
        };
      }
      return acc;
    }, {});
  }
  return {};
});

export const getSearchResults = (state) => state.entities.plans.temp.searched;

export const getCurrentDevicePlan = createSelector(
  getCurrentDeviceById,
  getPlans,
  (device, plans) => {
    const planId = device?.links.cellular[0].plan?.id;
    const devicePlan = Object.values(plans).filter((plan) => plan.id === planId);
    return toObjectByIds(devicePlan);
  }
);
