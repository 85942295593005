import update from 'immutability-helper';

import * as planActionTypes from '../plans/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
  temp: {
    searched: [],
  },
};

export default function orgsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SEARCH_FOR_ORG_BY_USER_ID_SUCCESS:
    case actionTypes.SEARCH_ORG_SUCCESS: {
      const { data, continues, limit, success } = action.response;
      const serializedData = Array.isArray(data) ? data : [data];
      return {
        ...state,
        temp: {
          ...state.temp,
          searched: serializedData,
          dataContinues: continues,
          limit,
          success,
        },
      };
    }

    case actionTypes.GET_ORG_INFO_SUCCESS: {
      const { data } = action.response;

      const newOrgData = { ...state.byId[data.id], ...data };

      return update(state, {
        byId: { [data.id]: { $set: newOrgData } },
        loaded: { [data.id]: { $set: true } },
      });
    }

    case actionTypes.GET_ORG_PACKAGES_SUCCESS: {
      const { data } = action.response;

      const pkg = data.length > 0 ? data[0] : {};
      const orgId = pkg.org_id ?? action.orgId;

      if (!orgId) {
        return state;
      }

      const orgData = state.byId[orgId] ?? {};
      const newOrgData = {
        ...orgData,
        package: {
          ...pkg,
          loaded: true,
        },
      };

      return update(state, {
        byId: { [orgId]: { $set: newOrgData } },
      });
    }

    case actionTypes.SET_ORG_PACKAGE_REQUEST: {
      const { orgid } = action.payload;

      const orgData = state.byId[orgid] ?? {};
      const newOrgData = {
        ...orgData,
        package: {
          ...orgData.package,
          loaded: false,
        },
      };

      return update(state, { byId: { [orgid]: { $set: newOrgData } } });
    }

    case actionTypes.SET_ORG_PACKAGE_SUCCESS: {
      const { orgid, response } = action;
      const { data } = response;

      const orgData = state.byId[orgid] ?? {};

      const newOrgData = {
        ...orgData,
        package: {
          ...data,
          loaded: true,
        },
      };

      return update(state, { byId: { [orgid]: { $set: newOrgData } } });
    }

    case actionTypes.GET_ORG_RELEASE_FLAGS_SUCCESS: {
      const { data } = action.response;

      const newOrgData = {
        ...state.byId[action.orgId],
        releaseFlags: data,
      };

      return update(state, { byId: { [action.orgId]: { $set: newOrgData } } });
    }

    case actionTypes.GET_ORG_BALANCE_SUCCESS: {
      const { data } = action.response;
      const newOrgData = {
        ...state.byId[data.orgid],
        balance: { ...state.byId[data.orgid]?.balance, ...data },
      };

      return update(state, { byId: { [data.orgid]: { $set: newOrgData } } });
    }

    case planActionTypes.GET_ORG_PLANS_SUCCESS: {
      const { orgId, response } = action;
      const { data } = response;

      const groupIds = [...new Set(data.map((plan) => plan.groupid))];
      const planIds = data.map((plan) => plan.id);

      return {
        ...state,
        byId: {
          ...state.byId,
          [orgId]: {
            ...state.byId[orgId],
            relationships: {
              groups: groupIds,
              plans: planIds,
            },
          },
        },
      };
    }

    case actionTypes.GET_ORG_BILLING_INFO_SUCCESS: {
      const { response } = action;
      const { data } = response;

      const newOrgData = {
        ...state.byId[data.id],
        billing: { ...state.byId[data.id]?.billing, ...data },
      };

      return update(state, { byId: { [data.id]: { $set: newOrgData } } });
    }

    case actionTypes.CLEAR_SEARCH_RESULTS: {
      return update(state, {
        temp: {
          searched: { $set: [] },
          $unset: ['dataContinues', 'limit', 'success'],
        },
      });
    }

    default:
      return state;
  }
}
