const PREFIX = 'ORGS_';

export const SEARCH_ORG_REQUEST = `${PREFIX}SEARCH_ORG_REQUEST`;
export const SEARCH_ORG_SUCCESS = `${PREFIX}SEARCH_ORG_SUCCESS`;
export const SEARCH_ORG_ERROR = `${PREFIX}SEARCH_ORG_ERROR`;

export const GET_ORG_INFO_REQUEST = `${PREFIX}GET_ORG_INFO_REQUEST`;
export const GET_ORG_INFO_SUCCESS = `${PREFIX}GET_ORG_INFO_SUCCESS`;
export const GET_ORG_INFO_ERROR = `${PREFIX}GET_ORG_INFO_ERROR`;

export const GET_ORG_RELEASE_FLAGS_REQUEST = `${PREFIX}GET_ORG_RELEASE_FLAGS_REQUEST`;
export const GET_ORG_RELEASE_FLAGS_SUCCESS = `${PREFIX}GET_ORG_RELEASE_FLAGS_SUCCESS`;
export const GET_ORG_RELEASE_FLAGS_ERROR = `${PREFIX}GET_ORG_RELEASE_FLAGS_ERROR`;

export const GET_ORG_BALANCE_REQUEST = `${PREFIX}GET_ORG_BALANCE_REQUEST`;
export const GET_ORG_BALANCE_SUCCESS = `${PREFIX}GET_ORG_BALANCE_SUCCESS`;
export const GET_ORG_BALANCE_ERROR = `${PREFIX}GET_ORG_BALANCE_ERROR`;

export const GET_ORG_BILLING_INFO_REQUEST = `${PREFIX}GET_ORG_BILLING_INFO_REQUEST`;
export const GET_ORG_BILLING_INFO_SUCCESS = `${PREFIX}GET_ORG_BILLING_INFO_SUCCESS`;
export const GET_ORG_BILLING_INFO_ERROR = `${PREFIX}GET_ORG_BILLING_INFO_ERROR`;

export const CLEAR_SEARCH_RESULTS = `${PREFIX}CLEAR_SEARCH_RESULTS`;
export const ENABLE_POSTPAY = `${PREFIX}ENABLE_POSTPAY`;
export const DISABLE_POSTPAY = `${PREFIX}DISABLE_POSTPAY`;

export const ADD_PROMO_CREDIT_REQUEST = `${PREFIX}ADD_PROMO_CREDIT_REQUEST`;
export const ADD_PROMO_CREDIT_SUCCESS = `${PREFIX}ADD_PROMO_CREDIT_SUCCESS`;
export const ADD_PROMO_CREDIT_ERROR = `${PREFIX}ADD_PROMO_CREDIT_ERROR`;

export const ADD_ACCOUNT_BALANCE_REQUEST = `${PREFIX}ADD_ACCOUNT_BALANCE_REQUEST`;
export const ADD_ACCOUNT_BALANCE_SUCCESS = `${PREFIX}ADD_ACCOUNT_BALANCE_SUCCESS`;
export const ADD_ACCOUNT_BALANCE_ERROR = `${PREFIX}ADD_ACCOUNT_BALANCE_ERROR`;

export const ADD_CHARGE_BALANCE_REQUEST = `${PREFIX}ADD_CHARGE_BALANCE_REQUEST`;
export const ADD_CHARGE_BALANCE_SUCCESS = `${PREFIX}ADD_CHARGE_BALANCE_SUCCESS`;
export const ADD_CHARGE_BALANCE_ERROR = `${PREFIX}ADD_CHARGE_BALANCE_ERROR`;

export const SEARCH_FOR_ORG_BY_USER_ID_REQUEST = `${PREFIX}SEARCH_FOR_ORG_BY_USER_ID_REQUEST`;
export const SEARCH_FOR_ORG_BY_USER_ID_SUCCESS = `${PREFIX}SEARCH_FOR_ORG_BY_USER_ID_SUCCESS`;
export const SEARCH_FOR_ORG_BY_USER_ID_ERROR = `${PREFIX}SEARCH_FOR_ORG_BY_USER_ID_ERROR`;

export const GET_ALL_PACKAGES_REQUEST = `${PREFIX}GET_ALL_PACKAGES_REQUEST`;
export const GET_ALL_PACKAGES_SUCCESS = `${PREFIX}GET_ALL_PACKAGES_SUCCESS`;
export const GET_ALL_PACKAGES_ERROR = `${PREFIX}GET_ALL_PACKAGES_ERROR`;

export const GET_ORG_PACKAGES_REQUEST = `${PREFIX}GET_ORG_PACKAGES_REQUEST`;
export const GET_ORG_PACKAGES_SUCCESS = `${PREFIX}GET_ORG_PACKAGES_SUCCESS`;
export const GET_ORG_PACKAGES_ERROR = `${PREFIX}GET_ORG_PACKAGES_ERROR`;

export const SET_ORG_PACKAGE_REQUEST = `${PREFIX}SET_ORG_PACKAGE_REQUEST`;
export const SET_ORG_PACKAGE_SUCCESS = `${PREFIX}SET_ORG_PACKAGE_SUCCESS`;
export const SET_ORG_PACKAGE_ERROR = `${PREFIX}SET_ORG_PACKAGE_ERROR`;
