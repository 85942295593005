import * as actionTypes from './actionTypes';

export const searchByDeviceOrSimDetails = (simValues, history) => ({
  type: actionTypes.SEARCH_BY_SIM_DETAILS,
  simValues,
  history,
});

export const getDevice = (deviceId) => ({
  type: actionTypes.GET_DEVICE_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_DEVICE_SUCCESS,
  errorType: actionTypes.GET_DEVICE_ERROR,
  url: `devices/${deviceId}`,
});

export const getDeviceByImei = (imei) => ({
  type: actionTypes.GET_DEVICE_BY_IMEI_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_DEVICE_BY_IMEI_SUCCESS,
  errorType: actionTypes.GET_DEVICE_BY_IMEI_ERROR,
  url: `devices?imei=${imei}`,
});

export const getDeviceAndPlanInfo = (deviceId) => ({
  type: actionTypes.GET_DEVICE_AND_PLAN_REQUEST,
  deviceId,
});

export const getActiveDevices = (orgId) => ({
  type: actionTypes.GET_ACTIVE_DEVICES_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_ACTIVE_DEVICES_SUCCESS,
  errorType: actionTypes.GET_ACTIVE_DEVICES_ERROR,
  url: `devices/count?stateCategory=activeDevices&orgid=${orgId}`,
});
