import * as actionTypes from './actionTypes';

export const setActiveGroupId = (groupId) => ({
  type: actionTypes.SET_ACTIVE_GROUP_ID,
  groupId,
});

export const unsetActiveGroupId = () => ({
  type: actionTypes.UNSET_ACTIVE_GROUP_ID,
});
