import { all, call, put, take, takeEvery } from 'redux-saga/effects';

import * as orgActions from 'common/store/entities/orgs/actions';
import * as orgActionTypes from 'common/store/entities/orgs/actionTypes';
import * as modalActions from 'common/store/ui/modals/actions';
import * as errorActions from 'common/store/ui/errors/actions';
import * as userActionTypes from 'common/store/entities/users/actionTypes';
import { request } from 'common/sagas/requests';

const POSTPAY_SCHEMES = {
  Standard: 'STANDARD_POSTPAID',
  Custom: 'AUTOMATED_CUSTOM_POSTPAID',
};
const PREPAY_SCHEME = 'STANDARD_PREPAID';

function* enablePostPay() {
  while (true) {
    const action = yield take(orgActionTypes.ENABLE_POSTPAY);
    const { mode, orgId, stripeId, terms } = action;

    const postpayScheme = POSTPAY_SCHEMES[mode];
    const postpayParams = {
      method: 'PUT',
      withCredentials: true,
      url: `/organizations/${orgId}/billing/postpay`,
      data: {
        billing_scheme: postpayScheme,
        terms,
        billingdata: stripeId,
      },
    };

    const postpayParamsResponse = yield call(request, postpayParams);
    if (postpayParamsResponse?.error) {
      yield put(errorActions.throwPostPayError(postpayParamsResponse.error));
    } else {
      yield all([
        put(orgActions.getBalanceInfo(orgId)),
        put(orgActions.getBillingInfo(orgId)),
        take(orgActionTypes.GET_ORG_BALANCE_SUCCESS),
        take(orgActionTypes.GET_ORG_BILLING_INFO_SUCCESS),
      ]);
      yield put(
        modalActions.openModal('successNotice', {
          title: 'Post Pay Enabled',
          text: `Post Pay has been enabled for Org ${orgId}`,
        })
      );
    }
  }
}

function* disablePostPay(action) {
  const { orgId } = action;

  const prepayParams = {
    method: 'PUT',
    withCredentials: true,
    url: `/organizations/${orgId}/billing/prepay`,
    data: {
      billing_scheme: PREPAY_SCHEME,
    },
  };

  yield call(request, prepayParams);
  yield all([
    put(orgActions.getBalanceInfo(orgId)),
    put(orgActions.getBillingInfo(orgId)),
    take(orgActionTypes.GET_ORG_BALANCE_SUCCESS),
    take(orgActionTypes.GET_ORG_BILLING_INFO_SUCCESS),
  ]);
}

function* addPromoCredit(action) {
  const { orgId, amount } = action;

  yield all([
    put(
      modalActions.openModal('successNotice', {
        title: 'Promo Credit Has Been Added',
        text: `Promo credit in the amount of $${parseFloat(amount).toFixed(
          2
        )} has been added for Org ${orgId}.`,
      })
    ),
    put(orgActions.getBalanceInfo(orgId)),
  ]);
}

function* addAccountBalance(action) {
  const { amount, orgId } = action;

  yield all([
    put(
      modalActions.openModal('successNotice', {
        title: 'Account Balance Has Been Added',
        text: `Account balance in the amount of $${parseFloat(amount).toFixed(
          2
        )} has been added for Org ${orgId}.`,
      })
    ),
    put(orgActions.getBalanceInfo(orgId)),
  ]);
}

function* addChargeBalance(action) {
  const { amount, orgid } = action;

  yield all([
    put(
      modalActions.openModal('successNotice', {
        title: 'Charge Balance Has Been Added',
        text: `Charge balance in the amount of $${parseFloat(amount).toFixed(
          2
        )} has been added for Org ${orgid}`,
      })
    ),
    put(orgActions.getBalanceInfo(orgid)),
  ]);
}

function* throwError(action) {
  const { response } = action;
  yield put(errorActions.throwOrgError(response));
}

function* orgsRootSaga() {
  yield all([
    enablePostPay(),
    takeEvery(orgActionTypes.ADD_PROMO_CREDIT_SUCCESS, addPromoCredit),
    takeEvery(orgActionTypes.ADD_ACCOUNT_BALANCE_SUCCESS, addAccountBalance),
    takeEvery(orgActionTypes.ADD_CHARGE_BALANCE_SUCCESS, addChargeBalance),
    takeEvery(orgActionTypes.DISABLE_POSTPAY, disablePostPay),
    takeEvery(orgActionTypes.SEARCH_ORG_ERROR, throwError),
    takeEvery(userActionTypes.SEARCH_USER_BY_EMAIL_ERROR, throwError),
  ]);
}

export default orgsRootSaga;
