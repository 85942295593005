import update from 'immutability-helper';

import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
  temp: {
    searched: [],
  },
};

export default function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GROUP_SEARCH_SUCCESS: {
      return update(state, {
        temp: {
          searched: {
            $set: action.response.data,
          },
        },
      });
    }

    case actionTypes.GET_GROUP_SUCCESS: {
      const {
        response: { data },
        response: {
          data: { id },
        },
      } = action;
      return update(state, {
        byId: { $merge: { [id]: { ...data, ...state.byId[id] } } },
        loaded: { $merge: { [id]: true } },
      });
    }

    case actionTypes.GROUP_ORG_MEMBER_REQUEST: {
      const { groupId } = action;
      const group = state.temp.searched.find((elm) => elm.id === groupId);

      return group
        ? update(state, {
            byId: { [groupId]: { $set: group } },
            loaded: { [groupId]: { $set: true } },
          })
        : state;
    }

    case actionTypes.GROUP_ORG_MEMBER_SUCCESS: {
      const { groupId, response } = action;
      return {
        ...state,
        byId: {
          [groupId]: {
            ...state.byId[groupId],
            users: response.data,
          },
        },
      };
    }

    case actionTypes.CLEAR_SEARCH_RESULTS: {
      return update(state, { temp: { searched: { $set: [] } } });
    }

    default:
      return state;
  }
}
