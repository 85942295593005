const PREFIX = 'TIERS_';

export const CREATE_PLAN_TIER_REQUEST = `${PREFIX}CREATE_PLAN_TIER_REQUEST`;
export const CREATE_PLAN_TIER_SUCCESS = `${PREFIX}CREATE_PLAN_TIER_SUCCESS`;
export const CREATE_PLAN_TIER_ERROR = `${PREFIX}CREATE_PLAN_TIER_ERROR`;

export const CREATE_PLAN_TIER = `${PREFIX}CREATE_PLAN_TIER`;

export const PERSIST_TIER_UPDATE_REQUEST = `${PREFIX}PERSIST_TIER_UPDATE_REQUEST`;
export const PERSIST_TIER_UPDATE_SUCCESS = `${PREFIX}PERSIST_TIER_UPDATE_SUCCESS`;
export const PERSIST_TIER_UPDATE_ERROR = `${PREFIX}PERSIST_TIER_UPDATE_ERROR`;

export const DELETE_TIER_REQUEST = `${PREFIX}DELETE_TIER_REQUEST`;
export const DELETE_TIER_SUCCESS = `${PREFIX}DELETE_TIER_SUCCESS`;
export const DELETE_TIER_ERROR = `${PREFIX}DELETE_TIER_ERROR`;

export const EDIT_TIERS = `${PREFIX}EDIT_TIERS`;

export const GET_TIER_VOLUME_TYPES_REQUEST = `${PREFIX}GET_TIER_VOLUME_TYPES_REQUEST`;
export const GET_TIER_VOLUME_TYPES_SUCCESS = `${PREFIX}GET_TIER_VOLUME_TYPES_SUCCESS`;
export const GET_TIER_VOLUME_TYPES_ERROR = `${PREFIX}GET_TIER_VOLUME_TYPES_ERROR`;

export const CREATE_TIER_VOLUME_TYPE_REQUEST = `${PREFIX}CREATE_TIER_VOLUME_TYPE_REQUEST`;
export const CREATE_TIER_VOLUME_TYPE_SUCCESS = `${PREFIX}CREATE_TIER_VOLUME_TYPE_SUCCESS`;
export const CREATE_TIER_VOLUME_TYPE_ERROR = `${PREFIX}CREATE_TIER_VOLUME_TYPE_ERROR`;
