const PREFIX = 'GROUPS_';

export const ADD_GROUP_REQUEST = `${PREFIX}ADD_GROUP_REQUEST`;
export const ADD_GROUP_SUCCESS = `${PREFIX}ADD_GROUP_SUCCESS`;
export const ADD_GROUP_ERROR = `${PREFIX}ADD_GROUP_ERROR`;

export const GROUP_SEARCH_REQUEST = `${PREFIX}GROUP_SEARCH_REQUEST`;
export const GROUP_SEARCH_SUCCESS = `${PREFIX}GROUP_SEARCH_SUCCESS`;
export const GROUP_SEARCH_ERROR = `${PREFIX}GROUP_SEARCH_ERROR`;

export const GET_GROUP_REQUEST = `${PREFIX}GET_GROUP_REQUEST`;
export const GET_GROUP_SUCCESS = `${PREFIX}GET_GROUP_SUCCESS`;
export const GET_GROUP_ERROR = `${PREFIX}GET_GROUP_ERROR`;

export const GROUP_ORG_MEMBER_REQUEST = `${PREFIX}GROUP_ORG_MEMBER_REQUEST`;
export const GROUP_ORG_MEMBER_SUCCESS = `${PREFIX}GROUP_ORG_MEMBER_SUCCESS`;
export const GROUP_ORG_MEMBER_ERROR = `${PREFIX}GROUP_ORG_MEMBER_ERROR`;

export const ADD_ORG_TO_GROUP_REQUEST = `${PREFIX}ADD_ORG_TO_GROUP_REQUEST`;
export const ADD_ORG_TO_GROUP_SUCCESS = `${PREFIX}ADD_ORG_TO_GROUP_SUCCESS`;
export const ADD_ORG_TO_GROUP_ERROR = `${PREFIX}ADD_ORG_TO_GROUP_ERROR`;

export const REMOVE_ORG_FROM_GROUP_REQUEST = `${PREFIX}REMOVE_ORG_FROM_GROUP_REQUEST`;
export const REMOVE_ORG_FROM_GROUP_SUCCESS = `${PREFIX}REMOVE_ORG_FROM_GROUP_SUCCESS`;
export const REMOVE_ORG_FROM_GROUP_ERROR = `${PREFIX}REMOVE_ORG_FROM_GROUP_ERROR`;

export const CLEAR_SEARCH_RESULTS = `${PREFIX}CLEAR_SEARCH_RESULTS`;
