import { headers } from './config';

const axios = require('axios');

export function getOrgInfo(orgid) {
  return axios({
    method: 'get',
    url: `${API_URL}/organizations/${orgid}`,
    withCredentials: true,
    headers,
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function getOrgStatus(orgid) {
  return axios({
    method: 'get',
    url: `${API_URL}/organizations/${orgid}/status`,
    withCredentials: true,
    headers,
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function getOrgGroups(orgid) {
  return axios({
    method: 'get',
    url: `${API_URL}/organizations/${orgid}/groups`,
    withCredentials: true,
    headers,
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function getOrgReleaseFlags(orgid) {
  return axios({
    method: 'get',
    url: `${API_URL}/releaseflags?orgid=${orgid}`,
    withCredentials: true,
    headers,
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function getOrgUsers(orgid) {
  return axios({
    method: 'get',
    url: `${API_URL}/organizations/${orgid}`,
    withCredentials: true,
    headers,
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function updatePreflightDurationForOrg(orgid, duration) {
  return axios({
    method: 'put',
    url: `${API_URL}/organizations/${orgid}`,
    withCredentials: true,
    headers,
    data: JSON.stringify({
      preflight_timeout_in_days: duration,
    }),
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}
