import React from 'react';
import { Button, Panel } from '@holokit/core';

import * as styles from './styles.module.scss';

const ConfirmationModal = ({
  title,
  text,
  warning,
  onConfirm,
  onCancel,
  onConfirmButtonText = 'Yes',
  onCancelButtonText = 'No',
  closeModal,
}) => (
  <Panel title={title} classes={styles.panel}>
    <div className={styles.text}>
      {text}
      {warning && <div className={styles.warningContainer}>{warning}</div>}
    </div>
    <div className={styles.buttons}>
      <Button
        onClick={() => {
          onCancel();
          closeModal();
        }}
        type="destructiveMinor"
      >
        {onCancelButtonText}
      </Button>
      <Button
        onClick={() => {
          onConfirm();
          closeModal();
        }}
        type="primary"
      >
        {onConfirmButtonText}
      </Button>
    </div>
  </Panel>
);

ConfirmationModal.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  onConfirmButtonText: 'Yes',
  onCancelButtonText: 'No',
};

export default ConfirmationModal;
