import update from 'immutability-helper';
import { toObjectByIds, toBooleanObjectByIds } from 'src/common/utils/objectFormatter/index';
import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
  temp: { searched: [] },
  regions: null,
};

// eslint-disable-next-line default-param-last
export default function plansReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ORG_PLANS_SUCCESS: {
      const { data } = action.response;

      const formattedPlans = toObjectByIds(data);
      const planIds = toBooleanObjectByIds(data);

      return update(state, {
        byId: { $set: formattedPlans },
        loaded: { $set: planIds },
      });
    }

    case actionTypes.SEARCH_PLANS_SUCCESS: {
      const { data } = action.response;
      return {
        ...state,
        temp: {
          ...state.temp,
          searched: data,
        },
      };
    }

    case actionTypes.UPDATE_PLAN_SUCCESS: {
      const { data } = action.response;
      const oldPlanIndex = state.temp.searched.findIndex((plan) => plan.id === data.id);

      const formattedPlan = toObjectByIds([data]);
      const planIds = toBooleanObjectByIds([data]);

      return update(state, {
        temp: { searched: { [oldPlanIndex]: { $set: data } } },
        byId: { $merge: formattedPlan },
        loaded: { $merge: planIds },
      });
    }

    case actionTypes.GET_PLAN_SUCCESS: {
      const { data } = action.response;
      const formattedPlan = toObjectByIds([data]);
      const planIds = toBooleanObjectByIds([data]);

      return update(state, {
        byId: { $merge: formattedPlan },
        loaded: { $merge: planIds },
      });
    }

    case actionTypes.GET_REGIONS_SUCCESS: {
      const { data } = action.response;

      return update(state, {
        regions: { $set: data },
      });
    }

    default:
      return state;
  }
}
