import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './common/sagas';
import entitiesReducer from './common/store/entities/index';
import stateReducer from './common/store/state';
import uiReducer from './common/store/ui';
import userReducer from './common/store/user/reducer';
import history from './createHistory';

const rootReducer = combineReducers({
  user: userReducer,
  entities: entitiesReducer,
  ui: uiReducer,
  state: stateReducer,
  router: connectRouter(history),
});

const sagaMiddleware = createSagaMiddleware();

const loggerFactory = () => createLogger({ collapsed: () => true });

const middlewares = [sagaMiddleware, promiseMiddleware(), routerMiddleware(history)];
if (process.env.NODE_ENV === 'development') middlewares.push(loggerFactory());

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: middlewares,
});

sagaMiddleware.run(rootSaga);

export default store;
