import * as actionTypes from './actionTypes';

const initialState = {
  openModalName: null,
  modalProps: {},
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN: {
      return {
        ...state,
        openModalName: action.openModalName,
        modalProps: action.modalProps,
      };
    }

    case actionTypes.CLOSE: {
      return {
        ...state,
        openModalName: null,
        modalProps: {},
      };
    }

    default:
      return state;
  }
};

export default uiReducer;
