import * as actionTypes from './actionTypes';

const initialState = {
  packages: [],
  loadingPackages: false,
};

export default function configReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_ALL_PACKAGES_REQUEST: {
      return {
        ...state,
        loadingPackages: true,
      };
    }

    case actionTypes.GET_ALL_PACKAGES_ERROR: {
      return {
        ...state,
        loadingPackages: false,
      };
    }

    case actionTypes.GET_ALL_PACKAGES_SUCCESS: {
      const { data } = action.response;
      return {
        ...state,
        packages: data,
        loadingPackages: false,
      };
    }

    default:
      return state;
  }
}
