import React from 'react';

const FieldGuide = () => (
  <iframe
    src="https://docs.google.com/presentation/d/e/2PACX-1vTu47ptEN1fuAKBHJUhchuN3CcAfNWcF8kakn2cdRou4AuWJv40E1VwAtwwoOxHGlFhAypAfeGFQTAp/embed?start=true&loop=false&delayms=60000"
    frameBorder="0"
    height="100%"
    width="100%"
    allowFullScreen="true"
    mozallowfullscreen="true"
    webkitallowfullscreen="true"
    title="Hologram Field Guide"
  />
);

export default FieldGuide;
