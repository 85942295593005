import { combineReducers } from 'redux';

import entitiesReducer from './entities/reducer';
import modalReducer from './modals/reducer';
import loadingReducer from './loading/reducer';
import errorReducer from './errors/reducer';

const uiReducer = combineReducers({
  entities: entitiesReducer,
  loading: loadingReducer,
  modal: modalReducer,
  error: errorReducer,
});

export default uiReducer;
