import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router';

import Orgs from 'pages/organizations';
import { Sidebar } from '@holokit/core';
import Plans from 'pages/plans';
import Flags from 'pages/releaseflags';
import Profiles from 'pages/profiles';
import Users from 'pages/users';
import SidebarItem from 'components/common/Nav/SidebarItem';
import { logout } from '../../common/store/user/actions';

import * as styles from './Authedmain.module.scss';

const AuthedRoutes = ({ user, logout_ }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();
  const homeActive = location.pathname.includes('/workspace/home');
  const releaseflagsActive = location.pathname.includes('/workspace/releaseflags');
  const plansActive = location.pathname.includes('/workspace/plans');
  const organizationsActive = location.pathname.includes('/workspace/organizations');
  const profilesActive = location.pathname.includes('/workspace/profiles');

  const usersActive = location.pathname.includes('/workspace/users');

  if (user.shouldLogout) {
    return <Redirect to="/login" />;
  }

  const adminRoles = ['adm', 'hdm'];
  const role = user?.user?.role;
  if (role && !adminRoles.includes(role)) {
    logout_();
  }

  if (!user.userIsAuthed) {
    return <div>loading</div>;
  }

  return (
    <div className={styles.container}>
      <Sidebar
        includeVersionNumber={false}
        isCollapsed={!sidebarOpen}
        collapseSidebar={() => {
          setSidebarOpen(!sidebarOpen);
        }}
      >
        <SidebarItem
          text="Home"
          iconName="application-home"
          active={homeActive}
          href="/workspace"
        />
        <SidebarItem
          text="Orgs"
          iconName="Organization"
          href="/workspace/organizations/search?by=org_details"
          active={organizationsActive}
        />
        <SidebarItem
          text="Flags"
          iconName="application-routes"
          href="/workspace/releaseflags"
          active={releaseflagsActive}
        />
        <SidebarItem
          text="Plans"
          iconName="ChangePlan"
          href="/workspace/plans/search"
          active={plansActive}
        />
        <SidebarItem
          text="Profiles"
          iconName="User"
          href="/workspace/profiles/wizard"
          active={profilesActive}
        />
        <SidebarItem
          text="Users"
          iconName="application-admin"
          href="/workspace/users"
          active={usersActive}
        />
      </Sidebar>
      <div className={styles.contentContainer}>
        <Switch>
          <Route path="/workspace/organizations" component={Orgs} />
          <Route path="/workspace/plans" component={Plans} />
          <Route path="/workspace/releaseflags" component={Flags} />
          <Route path="/workspace/profiles" component={Profiles} />
          <Route path="/workspace/users" component={Users} />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  logout_: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthedRoutes);
