import React from 'react';
import { Panel, Button } from '@holokit/core';
import { modalNames } from 'components/modals/ModalInstances';
import { addCommasToNumber } from '../../../common/utils/numberFormatter';

import * as styles from './styles.module.scss';

class BillingSummaryPanel extends React.Component {
  openDisablePostpayModal = () => {
    const { disablePostPayForOrg, orgInfo, openModal } = this.props;

    const modalProps = {
      title: 'Disable Post pay?',
      text: `Are you sure you want to disable postpay for ${orgInfo.name} (${orgInfo.id})? A valid credit card will need to be input on the dashboard.`,
      onConfirm: () => {
        disablePostPayForOrg(orgInfo.id);
      },
    };

    if (orgInfo.balance.balance < 0) {
      modalProps.warning = `They have a balance of -$${Math.abs(orgInfo.balance.balance)}.`;
    }

    openModal(modalNames.confirmation, modalProps);
  };

  openEnablePostPayModal = () => {
    const { openModal, orgInfo } = this.props;
    openModal(modalNames.enablePostPay, { orgId: orgInfo.id });
  };

  openAddBalanceModal = (chargeType) => {
    const { openModal, orgInfo } = this.props;
    openModal(modalNames.addBalance, { orgId: orgInfo.id, chargeType });
  };

  renderAvailableBalance = (balance) => {
    if (balance < 0) {
      return `-$${addCommasToNumber(Math.abs(balance))}`;
    }
    return `$${addCommasToNumber(balance)}`;
  };

  formatBillingScheme = (scheme) =>
    scheme
      .toLowerCase()
      .split('_')
      .map((letter) => `${letter.charAt(0).toUpperCase()}${letter.slice(1)}`)
      .join(' ');

  render() {
    const { orgInfo } = this.props;
    const { balance = false, billing = false } = orgInfo ?? {};
    const {
      promobalance,
      has_postpay: hasPostpay,
      billing_scheme: billingScheme,
      balance: availableBalance,
    } = balance;
    const { metadata: { pp_invoice_days_until_due: ppInvoiceDaysUntilDue = '' } = {} } = billing;

    return (
      <Panel title="Billing summary">
        {balance && (
          <div className={styles.billing_summary_container}>
            <div className={styles.left_column}>
              <div className={styles.balance_section}>
                Manage balance
                <div className={styles.balance_buttons}>
                  <Button type="secondary" onClick={() => this.openAddBalanceModal('Account')}>
                    Add balance
                  </Button>
                  <Button type="secondary" onClick={() => this.openAddBalanceModal('Charge')}>
                    Charge balance
                  </Button>
                </div>
              </div>
              <div className={styles.promo_section}>
                Promotional credit balance
                <div className={styles.promo_text}>
                  <span>${addCommasToNumber(promobalance)}</span>
                </div>
                <div className={styles.promo_button}>
                  <Button type="secondary" onClick={() => this.openAddBalanceModal('Promo Credit')}>
                    Add credit
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.right_column}>
              <table>
                <tbody>
                  <tr>
                    <th>Postpay enabled </th>
                    {hasPostpay ? (
                      <>
                        <td>Yes</td>
                        <td>
                          <Button type="secondary" onClick={this.openDisablePostpayModal}>
                            Disable postpay
                          </Button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>No</td>
                        <td>
                          <Button type="secondary" onClick={this.openEnablePostPayModal}>
                            Enable postpay
                          </Button>
                        </td>
                      </>
                    )}
                  </tr>
                  {!!hasPostpay && (
                    <tr>
                      <th>Net: </th>
                      <td>{ppInvoiceDaysUntilDue}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Billing scheme </th>
                    <td>{this.formatBillingScheme(billingScheme ?? '')}</td>
                  </tr>
                  {!hasPostpay && (
                    <tr>
                      <th>Account balance </th>
                      <td>{this.renderAvailableBalance(availableBalance)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Panel>
    );
  }
}

export default BillingSummaryPanel;
