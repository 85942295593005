// extracted by mini-css-extract-plugin
var _1 = "Font__body--OhXCaelB";
var _2 = "Font__body--10--cYte4aDY";
var _3 = "Font__body--20--d_pKa5Mq";
var _4 = "Font__body--30--dmvkoufh";
var _5 = "Font__caption--voYwACJm";
var _6 = "Font__caption--10--_yl5_Q5M";
var _7 = "Font__caption--20--MgeHBbsA";
var _8 = "Font__color--active--M5V0UzCo";
var _9 = "Font__color--destructive--ikUqvv8q";
var _a = "Font__color--light--_5AGN6jb";
var _b = "Font__color--lighter--p9JzLL34";
var _c = "Font__color--reversed--_e8_Q6HU";
var _d = "Font__color--text--WIOEf2Uo";
var _e = "Font__color--text-blue--LyOgsBXc";
var _f = "Font__color--text-green--UqNx6OFy";
var _10 = "Font__color--text-indigo--BxxxVMeW";
var _11 = "Font__color--text-mint--b6MozYD9";
var _12 = "Font__color--text-orange--GPyBLaTm";
var _13 = "Font__color--text-pink--sBKqpCDD";
var _14 = "Font__color--text-red--RcaPp5Lj";
var _15 = "Font__color--text-violet--uiBrZnC5";
var _16 = "Font__color--text-yellow--IK8Xhsvn";
var _17 = "Font__headline--UsZkjc3L";
var _18 = "Font__headline--10--KJOSmkdx";
var _19 = "Font__headline--20--GZxrseMJ";
var _1a = "Font__headline--30--gxMxXEuL";
var _1b = "Font__headline--40--E5dZ7QLV";
var _1c = "Font__headline--50--noF0K8tQ";
var _1d = "Font__headline--60--G4k7OKUL";
var _1e = "Font__headline--70--wBMf9Id8";
var _1f = "organization_name_header--b8zB5JXd";
var _20 = "organizations__container--uuRr_XzK";
export { _1 as "Font__body", _2 as "Font__body--10", _3 as "Font__body--20", _4 as "Font__body--30", _5 as "Font__caption", _6 as "Font__caption--10", _7 as "Font__caption--20", _8 as "Font__color--active", _9 as "Font__color--destructive", _a as "Font__color--light", _b as "Font__color--lighter", _c as "Font__color--reversed", _d as "Font__color--text", _e as "Font__color--text-blue", _f as "Font__color--text-green", _10 as "Font__color--text-indigo", _11 as "Font__color--text-mint", _12 as "Font__color--text-orange", _13 as "Font__color--text-pink", _14 as "Font__color--text-red", _15 as "Font__color--text-violet", _16 as "Font__color--text-yellow", _17 as "Font__headline", _18 as "Font__headline--10", _19 as "Font__headline--20", _1a as "Font__headline--30", _1b as "Font__headline--40", _1c as "Font__headline--50", _1d as "Font__headline--60", _1e as "Font__headline--70", _1f as "organization_name_header", _20 as "organizations__container" }
