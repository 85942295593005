import React from 'react';
import * as styles from './styles.module.scss';

const QuoteBuilder = () => (
  <a
    href="https://www.notion.so/teamhologram/BMP-Quote-Builder-and-Guide-65520a71750a40eca74a785126dc15c0"
    className={styles.container}
    target="_blank"
    rel="noreferrer"
  >
    Quote Builder Notion Page
  </a>
);

export default QuoteBuilder;
