import update from 'immutability-helper';

import * as actionTypes from './actionTypes';

const initialState = {
  selections: {
    activeGroup: null,
  },
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_GROUP_ID: {
      return {
        ...state,
        selections: {
          ...state.selections,
          activeGroup: action.groupId,
        },
      };
    }

    case actionTypes.UNSET_ACTIVE_GROUP_ID: {
      return update(state, {
        selections: { $unset: ['activeGroup'] },
      });
    }

    default:
      return state;
  }
};

export default uiReducer;
