import { Icon, Panel } from '@holokit/core';
import { selectOrgPackage } from 'common/selectors/entities/orgs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import featureFlagList from './FeatureFlagList';
import Features from './Features';
import ResponseCallout from './ResponseCallout';
import useCallouts from './useCallouts';

const ResponseCallouts = ({ callouts, removeCallout }) => (
  <div>
    {callouts.map(({ text, calloutType, uuid }) => (
      <ResponseCallout
        key={uuid}
        text={text}
        calloutType={calloutType}
        onClick={() => removeCallout(uuid)}
      />
    ))}
  </div>
);

const PackagesPanel = ({ orgInfo, setReleaseFlagsLoading }) => {
  const [loading, setLoading] = useState(false);
  const { callouts, addSuccessCallouts, addErrorCallouts, removeCallout } = useCallouts();

  const { id: orgId, releaseFlags: orgFlags } = orgInfo;
  const orgPackage = useSelector(selectOrgPackage(orgId));
  const packageId = orgPackage?.package_id ?? false;

  const configureFeatureFlagList = Object.entries(featureFlagList).reduce((acc, [key, val]) => {
    const flagProperties = val.map((item) => ({ ...item, disabled: !packageId }));
    const configuredEntry = { [key]: flagProperties };
    return { ...acc, ...configuredEntry };
  }, {});

  if (!orgFlags) return null;

  return (
    <Panel
      title="Plans and add-ons"
      icon={Icon.names.PROJECT}
      iconSize={Icon.sizes.MINOR}
      iconSvgProps={{ fill: '#6606C6' }}
      isLoading={loading}
    >
      <Features
        featureFlagList={configureFeatureFlagList}
        orgFlags={orgFlags}
        orgId={orgId}
        setPanelLoading={setLoading}
        addSuccessCallouts={addSuccessCallouts}
        addErrorCallouts={addErrorCallouts}
        setReleaseFlagsLoading={setReleaseFlagsLoading}
        preflightTimeout={orgInfo.preflight_timeout_in_days}
      />
      <ResponseCallouts callouts={callouts} removeCallout={removeCallout} />
    </Panel>
  );
};

export default PackagesPanel;
