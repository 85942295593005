import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './createStore';

import App from './pages/app';

const AppContainer = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppContainer />);
