import { combineReducers } from 'redux';

import config from './config/reducer';
import plans from './plans/reducer';

const stateReducer = combineReducers({
  config,
  plans,
});

export default stateReducer;
