import * as actionTypes from './actionTypes';

export const login = (email, password, rememberMe) => ({
  type: actionTypes.LOGIN_REQUEST,
  requestType: 'POST',
  successType: actionTypes.HANDLE_AUTH_FLOW,
  errorType: actionTypes.LOGIN_ERROR,
  url: `/auth/session?rememberme=${rememberMe ? 1 : 0}`,
  payload: { email, password, rememberMe },
});

export const logout = () => ({
  type: actionTypes.LOGOUT_REQUEST,
  requestType: 'POST',
  url: '/auth/sessiondestroy',
  successType: actionTypes.LOGOUT_SUCCESS,
  errorType: actionTypes.LOGOUT_ERROR,
});

export const getUserInfo = () => ({
  type: actionTypes.GET_USER_INFO_REQUEST,
  requestType: 'GET',
  url: '/users/me',
  successType: actionTypes.GET_USER_INFO_SUCCESS,
  errorType: actionTypes.GET_USER_INFO_ERROR,
});

export const forceToLoginScreen = () => ({
  type: actionTypes.FORCE_LOGIN,
});

export const setMfaFlow = (flow) => ({
  type: actionTypes.MFA_FLOW,
  payload: { flow },
});

export const saveMfaMethod = (method) => ({
  type: actionTypes.SAVE_MFA_METHOD,
  payload: { method },
});

export const setMfaModal = (showModal) => ({
  type: actionTypes.SET_MFA_MODAL,
  payload: { showModal },
});

export const authenticateTotp = (totpCode) => ({
  type: actionTypes.AUTHENTICATE_TOTP,
  payload: {
    totpCode,
  },
});

export const resendTotp = () => ({
  type: actionTypes.RESEND_TOTP,
});

export const startAuthentication = () => ({
  type: actionTypes.START_MFA_AUTHENTICATION,
});

export const clearMfaErrors = () => ({
  type: actionTypes.CLEAR_MFA_ERRORS,
});

export const resetMfaFlow = () => ({
  type: actionTypes.RESET_MFA_FLOW,
});
