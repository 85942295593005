import { all } from 'redux-saga/effects';

import entitiesRootSaga from './entities';
import rootRequestSaga from './requests';
import usersRootSaga from './user';

function* rootSaga() {
  yield all([entitiesRootSaga(), rootRequestSaga(), usersRootSaga()]);
}

export default rootSaga;
