import React from 'react';
import { toast } from 'react-toastify';
import { Icon } from '@holokit/core';
import * as styles from './styles.module.scss';

export const Toast = ({ icon, size = 'major', headline, body }) => (
  <div className={styles.toastContent}>
    {icon && (
      <div className={styles.toastIconWrapper}>
        <Icon name={icon} size={size} svgProps={{ style: { file: '#FFFFFF' } }} />
      </div>
    )}
    <div className={styles.toastTextWrapper}>
      {headline && <h5 className={styles.toastHeadline}>{headline}</h5>}
      {body && <p className={styles.toastBody}>{body}</p>}
    </div>
  </div>
);

export const CloseButton = () => {
  const close = () => {
    toast.dismiss();
  };

  return (
    <button className={styles.toastifyCloseButton} onClick={close}>
      <Icon name="Close" size="micro" svgProps={{ style: { fill: '#ffffff' } }} />
    </button>
  );
};
