import * as actionTypes from './actionTypes';

export const searchOrgs = (searchTerm, isId = false, limit = 10) => ({
  type: actionTypes.SEARCH_ORG_REQUEST,
  requestType: 'GET',
  successType: actionTypes.SEARCH_ORG_SUCCESS,
  errorType: actionTypes.SEARCH_ORG_ERROR,
  url: isId ? `/organizations/${searchTerm}` : `/organizations?q=${searchTerm}&limit=${limit}`,
});

export const getOrgInfo = (orgId) => ({
  type: actionTypes.GET_ORG_INFO_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_ORG_INFO_SUCCESS,
  errorType: actionTypes.GET_ORG_INFO_ERROR,
  url: `/organizations/${orgId}`,
  orgId,
});

export const getOrgPackages = (orgId) => ({
  type: actionTypes.GET_ORG_PACKAGES_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_ORG_PACKAGES_SUCCESS,
  errorType: actionTypes.GET_ORG_PACKAGES_ERROR,
  url: `/organizations/${orgId}/billing/packages`,
  orgId,
  passthroughData: { orgId },
});

export const getOrgReleaseFlags = (orgId) => ({
  type: actionTypes.GET_ORG_RELEASE_FLAGS_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_ORG_RELEASE_FLAGS_SUCCESS,
  errorType: actionTypes.GET_ORG_RELEASE_FLAGS_ERROR,
  url: `/releaseflags?orgid=${orgId}`,
  orgId,
  passthroughData: { orgId },
});

export const getBalanceInfo = (orgId) => ({
  type: actionTypes.GET_ORG_BALANCE_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_ORG_BALANCE_SUCCESS,
  errorType: actionTypes.GET_ORG_BALANCE_ERROR,
  url: `/organizations/${orgId}/balance`,
});

export const getBillingInfo = (orgId) => ({
  type: actionTypes.GET_ORG_BILLING_INFO_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_ORG_BILLING_INFO_SUCCESS,
  errorType: actionTypes.GET_ORG_BILLING_INFO_ERROR,
  url: `/organizations/${orgId}/billing`,
});

export const clearSearchResults = () => ({
  type: actionTypes.CLEAR_SEARCH_RESULTS,
});

export const searchForOrgsByUserId = (userId, limit = 10) => ({
  type: actionTypes.SEARCH_FOR_ORG_BY_USER_ID_REQUEST,
  requestType: 'GET',
  successType: actionTypes.SEARCH_FOR_ORG_BY_USER_ID_SUCCESS,
  errorType: actionTypes.SEARCH_FOR_ORG_BY_USER_ID_ERROR,
  url: `organizations?userid=${userId}&limit=${limit}`,
  passthroughData: { userId },
});

export const enablePostpayForOrg = (orgId, terms, stripeId, mode) => ({
  type: actionTypes.ENABLE_POSTPAY,
  mode,
  orgId,
  stripeId,
  terms,
});

export const disablePostPayForOrg = (orgId) => ({
  type: actionTypes.DISABLE_POSTPAY,
  orgId,
});

export const addPromoCreditForOrg = (orgId, amount, transactionType, description) => ({
  type: actionTypes.ADD_PROMO_CREDIT_REQUEST,
  requestType: 'POST',
  url: `/organizations/${orgId}/addpromobalance`,
  successType: actionTypes.ADD_PROMO_CREDIT_SUCCESS,
  errorType: actionTypes.ADD_PROMO_CREDIT_ERROR,
  passthroughData: { orgId, amount },
  payload: {
    amount,
    description,
    transactionType,
  },
});

export const addAccountBalanceForOrg = (orgId, amount, transactionTypeId, description) => ({
  type: actionTypes.ADD_ACCOUNT_BALANCE_REQUEST,
  requestType: 'POST',
  url: `/organizations/${orgId}/addbalance`,
  successType: actionTypes.ADD_ACCOUNT_BALANCE_SUCCESS,
  errorType: actionTypes.ADD_ACCOUNT_BALANCE_ERROR,
  passthroughData: { orgId, amount },
  payload: {
    amount,
    transactionTypeId,
    description,
  },
});

export const addChargeBalanceForOrg = (orgid, amount, transactionType, description) => ({
  type: actionTypes.ADD_CHARGE_BALANCE_REQUEST,
  successType: actionTypes.ADD_CHARGE_BALANCE_SUCCESS,
  errorTypes: actionTypes.ADD_ACCOUNT_BALANCE_ERROR,
  requestType: 'POST',
  url: '/organizations/chargebalance',
  passthroughData: { orgid, amount },
  payload: {
    amount,
    transactionType,
    description,
    orgid,
  },
});

export const setOrgPackage = ({ orgid, packageId, assignedPrice, packageAssignmentId }) => ({
  type: actionTypes.SET_ORG_PACKAGE_REQUEST,
  successType: actionTypes.SET_ORG_PACKAGE_SUCCESS,
  errorTypes: actionTypes.SET_ORG_PACKAGE_ERROR,
  requestType: packageAssignmentId ? 'PUT' : 'POST',
  url: `/organizations/${orgid}/billing/packages${
    packageAssignmentId ? `/${packageAssignmentId}` : ''
  }`,
  passthroughData: { orgid },
  payload: {
    orgid,
    package_id: packageId,
    assigned_price: assignedPrice,
    ...(packageAssignmentId ? { package_assignment_id: packageAssignmentId } : {}),
  },
});
