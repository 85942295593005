import update from 'immutability-helper';
import { toObjectByIds, toBooleanObjectByIds } from 'src/common/utils/objectFormatter/index';
import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
  activeDevices: null,
};

export default function devicesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_DEVICE_SUCCESS: {
      const { data } = action.response;

      return update(state, {
        byId: {
          [data.id]: { $set: data },
        },
        loaded: {
          [data.id]: { $set: true },
        },
      });
    }

    case actionTypes.GET_DEVICE_BY_IMEI_SUCCESS: {
      const { data } = action.response;

      const formattedDevice = toObjectByIds(data);
      const device = toBooleanObjectByIds(data);

      return update(state, {
        byId: { $merge: formattedDevice },
        loaded: { $merge: device },
      });
    }

    case actionTypes.GET_ACTIVE_DEVICES_SUCCESS: {
      const { data } = action.response;

      return update(state, {
        activeDevices: { $set: data },
      });
    }

    default:
      return state;
  }
}
