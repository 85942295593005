const PREFIX = 'USER_';

export const LOGIN_REQUEST = `${PREFIX}LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${PREFIX}LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${PREFIX}LOGIN_ERROR`;

export const LOGOUT_REQUEST = `${PREFIX}LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${PREFIX}LOGOUT_SUCCESS`;
export const LOGOUT_ERROR = `${PREFIX}LOGOUT_ERROR`;

export const GET_USER_INFO_REQUEST = `${PREFIX}GET_USER_INFO_REQUEST`;
export const GET_USER_INFO_SUCCESS = `${PREFIX}GET_USER_INFO_SUCCESS`;
export const GET_USER_INFO_ERROR = `${PREFIX}GET_USER_INFO_ERROR`;

export const FORCE_LOGIN = `${PREFIX}FORCE_LOGIN`;

export const HANDLE_AUTH_FLOW = `${PREFIX}HANDLE_AUTH_FLOW`;
export const MFA_FLOW = `${PREFIX}MFA_FLOW`;
export const SAVE_MFA_METHOD = `${PREFIX}SAVE_MFA_METHOD`;
export const SET_MFA_MODAL = `${PREFIX}SET_MFA_MODAL`;
export const AUTHENTICATE_TOTP = `${PREFIX}AUTHENTICATE_TOTP`;
export const HANDLE_MFA_ERROR = `${PREFIX}HANDLE_MFA_ERROR`;
export const RESEND_TOTP = `${PREFIX}RESEND_TOTP`;
export const AUTHENTICATE_TOTP_REQUEST = `${PREFIX}AUTHENTICATE_TOTP_REQUEST`;
export const RESEND_TOTP_REQUEST = `${PREFIX}RESEND_TOTP_REQUEST`;
export const RESEND_TOTP_REQUEST_SUCCESS = `${PREFIX}RESEND_TOTP_REQUEST_SUCCESS`;
export const START_MFA_AUTHENTICATION = `${PREFIX}START_MFA_AUTHENTICATION`;
export const CLEAR_MFA_ERRORS = `${PREFIX}CLEAR_MFA_ERRORS`;
export const RESET_MFA_FLOW = `${PREFIX}RESET_MFA_FLOW`;
