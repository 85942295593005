import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
};

export default function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CARRIERS_SUCCESS: {
      const { data } = action.response;
      return {
        ...state,
        byId: data.reduce(
          (carriers, currentCarrier) => ({
            ...carriers,
            [currentCarrier.id]: currentCarrier,
          }),
          {}
        ),
        loaded: data.reduce(
          (carriers, currentCarrier) => ({
            ...carriers,
            [currentCarrier.id]: true,
          }),
          {}
        ),
      };
    }

    default:
      return state;
  }
}
