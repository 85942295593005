import { formatMoney } from 'common/utils/numberFormatter';
import React from 'react';
import * as styles from './styles.module.scss';

export default (props) => {
  const { zones } = props;

  return (
    <table className={styles.ZoneTableSubTable}>
      <tbody>
        {Object.keys(zones ?? {}).map((zoneName) => (
          <tr key={zoneName}>
            <td className={`${styles.ZoneTableCell} ${styles.ZoneColumn}`}>{zoneName}</td>
            <td className={`${styles.ZoneTableCell} ${styles.MrcColumn}`}>
              {formatMoney(zones[zoneName].amount, '$')}
            </td>
            <td className={`${styles.ZoneTableCell} ${styles.SmsColumn}`}>
              {formatMoney(zones[zoneName].sms, '$')}
            </td>
            <td className={`${styles.ZoneTableCell} ${styles.OverageColumn}`}>
              {formatMoney(zones[zoneName].overage, '$')}
            </td>
            <td className={`${styles.ZoneTableCell} ${styles.CarriersColumn}`}>
              <ul className={styles.ListSmall}>
                {Object.keys(zones[zoneName].carriers ?? {}).map((id) => (
                  <li key={`${zoneName}-${id}`}>
                    {zones[zoneName].carriers[id].csp} [{id}]
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
