/**
 * FUNCTION: toObjectByIds
 * PARAMETERS:
 * array (required) - this is an array of objects that needs to be converted into an object
 * RETURN VALUE: an object where each key corresponds to an id and the values correspond to the
 * values of the object
 *
 * example: [{ id: 1, name: 'Rudolph', age: 2},{ id: 2, name: 'Dasher', age: 5}]
 * result: {
 *  1: { id: 1, name: 'Rudolph', age: 2},
 *  2: { id: 2, name: 'Dasher', age: 5},
 * }
 */

export const toObjectByIds = (array, relationships, idKey = 'id') =>
  array.reduce(
    (obj, val) => ({
      ...obj,
      [val[idKey]]: {
        ...val,
        relationships:
          relationships?.reduce(
            (acc, relationshipKey) => ({
              ...acc,
              [relationshipKey]: [],
            }),
            {}
          ) ?? {},
      },
    }),
    {}
  );

/**
 * FUNCTION: toBooleanObjectByIds
 * PARAMETERS:
 * array (required) - this is an array of objects that needs to converted into an object
 * RETURN VALUE: an object where each key corresponds to an id and the values are set to
 * a true boolean
 *
 * example: [{ id: 1, name: 'Rudolph', age: 2},{ id: 2, name: 'Dasher', age: 5}]
 * result: {
 *  1: true,
 *  2: true,
 * }
 *
 * NOTE: this is different than the function toObjectByIds in that toObjectByIds maintains
 * the information in the objects, while toBooleanObjectByIds keeps the ids and sets it to true
 * (best used for loaded object in state)
 */

export const toBooleanObjectByIds = (array, idKey = 'id') =>
  array.reduce(
    (obj, val) => ({
      ...obj,
      [val[idKey]]: true,
    }),
    {}
  );
