import { round, isNull } from 'lodash';

/*
FUNCTION: toByteString
PARAMETERS:
amount (required) - number - a number of bytes to be converted into a
 string or object
roundToDecimal (optional) - number - number of decimal points to round the byte amount to
 after conversion
returnObject (optional) - boolean - whether or not to return a human readable string or
an object containing elements of the string (bytevalue and unit)
RETURN VALUE: either a string or an object containing the converted byte value and
 the unit it was converted to
*/

export const toByteString = (amount, roundToDecimal = 2, returnObject = false) => {
  let byteValue = '';
  let unit = '';
  const num = Number(amount);
  if (num < 1000) {
    byteValue = round(num * 1, roundToDecimal);
    unit = 'B';
  } else if (num >= 1000 && num < 1000000) {
    byteValue = round(num / 1000, roundToDecimal);
    unit = 'KB';
  } else if (num >= 1000000 && num < 1000000000) {
    byteValue = round(num / 1000000, roundToDecimal);
    unit = 'MB';
  } else if (num >= 1000000000) {
    byteValue = round(num / 1000000000, roundToDecimal);
    unit = 'GB';
  }
  return returnObject ? { byteValue, unit } : `${byteValue}  ${unit}`;
};

/**
 * FUNCTION: addCommasToNumber
 * PARAMETERS: amount (required)
 * RETURNS: number with commas where appropriate
 */

export const addCommasToNumber = (amount) => {
  if (Number.isNaN(amount) || isNull(amount)) return '-';
  const splitTotal = String(amount).split('.');
  const dollarsWithCommas = Number(splitTotal[0]).toLocaleString();
  const cents = String(parseFloat(amount).toFixed(2)).split('.')[1];
  return `${dollarsWithCommas}.${cents}`;
};

export const formatMoney = (value, dollarSign = '') => {
  const stringTotal = parseFloat(value).toString();
  if (!stringTotal.split('').includes('.')) {
    return `${dollarSign}${parseFloat(value).toFixed(2)}`;
  }
  const cents = stringTotal.split('.')[1].split('');
  if (cents.length < 2) {
    return `${dollarSign}${parseFloat(value).toFixed(2)}`;
  }
  if (cents.length >= 2) {
    return `${dollarSign}${parseFloat(value)}`;
  }
};
