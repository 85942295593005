import React from 'react';
import { Route, Switch } from 'react-router';

import AllUsersPanel from 'components/users/AllUsersPanel';
import SingleUser from 'components/users/SingleUser';
import AuthedMainLayout from 'components/layouts/AuthedMain';

const UsersIndex = () => (
  <AuthedMainLayout
    navOptions={[
      {
        text: 'Users by Org',
        pathname: '/workspace/users',
        isDefault: true,
      },
    ]}
  >
    <Switch>
      <Route exact path="/workspace/users" component={AllUsersPanel} />
      <Route path="/workspace/users/:userid" component={SingleUser} />
    </Switch>
  </AuthedMainLayout>
);

export default UsersIndex;
