import { ComplexIcon, Tooltip } from '@holokit/core';
import _classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router';

const SidebarItem = ({
  active,
  text,
  className,
  hidden,
  href,
  iconName,
  showTooltip,
  disabled,
}) => {
  if (hidden) return null;
  const classes = _classNames('Sidebar__item', className, {
    'Sidebar__item--active': active && !disabled,
    'Sidebar__item--disabled': disabled,
  });

  const item = (
    <Link className={classes} to={!disabled && href}>
      {iconName && (
        <div className="Sidebar__item__icon-container">
          <ComplexIcon name={iconName} classes="Sidebar__item__icon" />
        </div>
      )}
      <span className="Sidebar__item__text">{text}</span>
    </Link>
  );

  return showTooltip ? (
    <Tooltip
      block
      position="right"
      hoverOpenDelay={1000}
      content={text}
      className="Sidebar__item__tooltip"
    >
      {item}
    </Tooltip>
  ) : (
    item
  );
};

export default SidebarItem;
