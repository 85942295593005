import * as actionTypes from './actionTypes';

export const searchGroups = (searchTerm) => ({
  type: actionTypes.GROUP_SEARCH_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GROUP_SEARCH_SUCCESS,
  errorType: actionTypes.GROUP_SEARCH_ERROR,
  url: `/groups?name=${searchTerm}`,
});

export const getGroupById = (groupId) => ({
  type: actionTypes.GET_GROUP_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_GROUP_SUCCESS,
  errorType: actionTypes.GET_GROUP_ERROR,
  url: `/groups/${groupId}`,
});

export const getGroupMembers = (groupId) => ({
  type: actionTypes.GROUP_ORG_MEMBER_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GROUP_ORG_MEMBER_SUCCESS,
  errorType: actionTypes.GROUP_ORG_MEMBER_ERROR,
  url: `/groups/${groupId}/orgs`,
  groupId,
  passthroughData: { groupId },
});

export const addGroup = (groupName) => ({
  type: actionTypes.ADD_GROUP_REQUEST,
  requestType: 'POST',
  successType: actionTypes.ADD_GROUP_SUCCESS,
  errorType: actionTypes.ADD_GROUP_ERROR,
  url: '/groups',
  payload: { name: groupName },
});

export const addOrgToGroup = (groupId, orgId) => ({
  type: actionTypes.ADD_ORG_TO_GROUP_REQUEST,
  requestType: 'POST',
  successType: actionTypes.ADD_ORG_TO_GROUP_SUCCESS,
  errorType: actionTypes.ADD_ORG_TO_GROUP_ERROR,
  url: `/groups/${groupId}/addtogroup/${orgId}`,
});

export const removeOrgFromGroup = (groupId, orgId) => ({
  type: actionTypes.REMOVE_ORG_FROM_GROUP_REQUEST,
  requestType: 'POST',
  successType: actionTypes.REMOVE_ORG_FROM_GROUP_SUCCESS,
  errorType: actionTypes.REMOVE_ORG_FROM_GROUP_ERROR,
  url: `/groups/${groupId}/removefromgroup/${orgId}`,
});

export const clearSearchResults = () => ({
  type: actionTypes.CLEAR_SEARCH_RESULTS,
});
