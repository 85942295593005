import { combineReducers } from 'redux';

import carriers from './carriers/reducer';
import devices from './devices/reducer';
import groups from './groups/reducer';
import offers from './offers/reducer';
import orgs from './orgs/reducer';
import plans from './plans/reducer';
import tiers from './tiers/reducer';
import users from './users/reducer';
import links from './links/reducer';

const entitiesReducer = combineReducers({
  carriers,
  devices,
  groups,
  links,
  offers,
  orgs,
  plans,
  tiers,
  users,
});

export default entitiesReducer;
