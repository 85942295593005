import React from 'react';
import { Button, Panel } from '@holokit/core';

import * as styles from './styles.module.scss';

const ConfimrationModal = ({ title, text, buttonText, closeModal }) => (
  <Panel title={title} classes={styles.panel} type="success">
    <div>{text}</div>
    <div className={styles.buttons}>
      <Button onClick={closeModal} type="secondary">
        {buttonText}
      </Button>
    </div>
  </Panel>
);

ConfimrationModal.defaultProps = {
  buttonText: 'Close',
};

export default ConfimrationModal;
