// extracted by mini-css-extract-plugin
var _1 = "DeepMenu--_9MFYYDd";
var _2 = "DeepMenu__inner--ptRrkJKF";
var _3 = "DeepMenu__item--vu3AkjWz";
var _4 = "DeepMenu__item--active--aG9UVg5c";
var _5 = "DeepMenu__item--disabled--Tc1C8SIC";
var _6 = "Font__body--zwVu1ItE";
var _7 = "Font__body--10--M5QXxwAX";
var _8 = "Font__body--20--wBTGFpfe";
var _9 = "Font__body--30--I01V6L2U";
var _a = "Font__caption--w9hCAVdb";
var _b = "Font__caption--10--vBv5cdh5";
var _c = "Font__caption--20--qtq4c3Pb";
var _d = "Font__color--active--m3_2wwjb";
var _e = "Font__color--destructive--HJOm02p9";
var _f = "Font__color--light--QB5cTV6X";
var _10 = "Font__color--lighter--LxXW7mtk";
var _11 = "Font__color--reversed--L_ufIPAG";
var _12 = "Font__color--text--Uvw_Y5z2";
var _13 = "Font__color--text-blue--SIiCavcI";
var _14 = "Font__color--text-green--VxCidr8M";
var _15 = "Font__color--text-indigo--OQovopMA";
var _16 = "Font__color--text-mint--GK85fYih";
var _17 = "Font__color--text-orange--oO1kDaU5";
var _18 = "Font__color--text-pink--ZkdZYayw";
var _19 = "Font__color--text-red--x7bzdxdA";
var _1a = "Font__color--text-violet--TOvOFIJ3";
var _1b = "Font__color--text-yellow--yMPFwuNj";
var _1c = "Font__headline--AY6L6c2e";
var _1d = "Font__headline--10--ZMEGcUzX";
var _1e = "Font__headline--20--qv0uQ8Tc";
var _1f = "Font__headline--30--WhzeX143";
var _20 = "Font__headline--40--fLBPHwr0";
var _21 = "Font__headline--50--ClEb8OZC";
var _22 = "Font__headline--60--dna6e0BA";
var _23 = "Font__headline--70--AJePxZHR";
var _24 = "deepMenu--gKaKiiIv";
var _25 = "item--yAMdEc02";
var _26 = "itemActive--_lYYgjDl";
var _27 = "leftContainer--uA20z0TK";
export { _1 as "DeepMenu", _2 as "DeepMenu__inner", _3 as "DeepMenu__item", _4 as "DeepMenu__item--active", _5 as "DeepMenu__item--disabled", _6 as "Font__body", _7 as "Font__body--10", _8 as "Font__body--20", _9 as "Font__body--30", _a as "Font__caption", _b as "Font__caption--10", _c as "Font__caption--20", _d as "Font__color--active", _e as "Font__color--destructive", _f as "Font__color--light", _10 as "Font__color--lighter", _11 as "Font__color--reversed", _12 as "Font__color--text", _13 as "Font__color--text-blue", _14 as "Font__color--text-green", _15 as "Font__color--text-indigo", _16 as "Font__color--text-mint", _17 as "Font__color--text-orange", _18 as "Font__color--text-pink", _19 as "Font__color--text-red", _1a as "Font__color--text-violet", _1b as "Font__color--text-yellow", _1c as "Font__headline", _1d as "Font__headline--10", _1e as "Font__headline--20", _1f as "Font__headline--30", _20 as "Font__headline--40", _21 as "Font__headline--50", _22 as "Font__headline--60", _23 as "Font__headline--70", _24 as "deepMenu", _25 as "item", _26 as "itemActive", _27 as "leftContainer" }
