import { push } from 'connected-react-router';
import { isEmpty, omit } from 'lodash';
import { all, call, put, race, take, takeEvery, select } from 'redux-saga/effects';

import * as groupActions from 'common/store/entities/groups/actions';
import * as groupActionTypes from 'common/store/entities/groups/actionTypes';
import * as planActions from 'common/store/entities/plans/actions';
import * as planActionTypes from 'common/store/entities/plans/actionTypes';
import * as modalActions from 'common/store/ui/modals/actions';
import * as tierActions from 'common/store/entities/tiers/actions';
import * as tierActionTypes from 'common/store/entities/tiers/actionTypes';
import * as planStateActions from 'common/store/state/plans/actions';
import { getPlans } from 'common/selectors/entities/plans';
import { getTiers } from 'common/selectors/entities/tiers';

const DEFAULT_PLAN_PARAMS = {
  partnerid: 1,
  recurring: 1,
  enabled: 1,
  billingperiod: 1,
  trialdays: 0,
  developerplan: 0,
};

function* createTier(planId, tierData, tierVolume) {
  const tierTypes = yield select(getTiers);
  const tierMissingTierType = !tierTypes.byId[tierData.tier];

  if (tierMissingTierType) {
    yield all([
      put(tierActions.createTierType(tierVolume, tierData.tier, `${tierVolume} Active Devices`)),
      take([
        // take both the action type, and also match the volume if more than one
        // tier is being made
        tierActionTypes.CREATE_TIER_VOLUME_TYPE_SUCCESS,
        (action) => action.volume === tierData.tierVolume,
      ]),
    ]);
  }

  yield put(tierActions.createPlanTier(planId, tierData));
}

function* createPlan(action) {
  const { groupData, orgId, planData, tiers } = action;

  // create group if needed
  let groupId;
  if (groupData.isNew) {
    yield put(groupActions.addGroup(groupData.name));
    const newGroupAction = yield take(groupActionTypes.ADD_GROUP_SUCCESS);
    const { data } = newGroupAction.response;
    groupId = data.id;
  } else {
    groupId = groupData.id;
  }

  // add org to group
  if (orgId) {
    yield put(groupActions.addOrgToGroup(groupId, orgId));
    yield take(groupActionTypes.ADD_ORG_TO_GROUP_SUCCESS);
  }

  // create plan
  const planParams = {
    ...DEFAULT_PLAN_PARAMS,
    groupid: groupId,
    data: planData.data,
    name: planData.name,
    description: planData.description,
    cellular_coverage_region_id: planData.region,
    zones: {
      [planData.zones.type]: omit(planData.zones, 'type'),
    },
  };
  yield put(planActions.persistPlan(planParams));
  const planAction = yield take(planActionTypes.PERSIST_PLAN_SUCCESS);
  const planDataResponse = planAction.response.data;
  const planId = planDataResponse.id;

  // if needed, put tiers
  if (tiers && Object.keys(tiers).length !== 0) {
    const individualTiers = Object.values(tiers);
    yield all(
      individualTiers.map((tier) =>
        call(
          createTier,
          planId,
          {
            zone: planData.zones.type,
            tier: `${tier.tier}DV`,
            amount: tier.amount,
            sms: tier.sms,
            overage: tier.overage,
          },
          tier.tier
        )
      )
    );
  }

  return {
    planId,
    name: planData.name,
  };
}

function* createPlanHandler() {
  while (true) {
    const action = yield take(planActionTypes.CREATE_PLAN);
    // use race to catch any errors in further down sagas
    const [success, ...rest] = yield race([
      call(createPlan, action),
      take(groupActionTypes.ADD_GROUP_ERROR),
      take(groupActionTypes.ADD_ORG_TO_GROUP_ERROR),
      take(planActionTypes.PERSIST_PLAN_ERROR),
      take(tierActionTypes.CREATE_PLAN_TIER_ERROR),
    ]);

    if (success) {
      yield put({
        type: planActionTypes.CREATE_OR_EDIT_PLAN_SUCCESS,
        planId: success.planId,
        name: success.name,
      });
      yield put(modalActions.closeModal());
      yield put(push('/workspace/plans/create/success'));
    } else {
      const error = rest.find((errorResponse) => !!errorResponse);
      const { response } = error;
      yield put(planStateActions.throwError(response));
    }
  }
}

function* editPlanHandler() {
  const success = yield take(planActionTypes.UPDATE_PLAN_SUCCESS);
  yield put({
    type: planActionTypes.CREATE_OR_EDIT_PLAN_SUCCESS,
    planId: success.response.data.id,
    name: success.response.data.name,
  });
  yield put(modalActions.closeModal());
  yield put(push('/workspace/plans/:id/edit/success'));
}

function* diffAndUpdateTiers(action) {
  const { planId, newTiers, oldTiers } = action;
  const existingTierIds = oldTiers ? Object.keys(oldTiers) : [];
  const newTierIds = newTiers ? Object.keys(newTiers) : [];

  const deletedTiers = existingTierIds.filter((tierId) => !newTiers[tierId]);
  const addedTiers = newTierIds.filter((tierId) => !oldTiers[tierId]);

  const plans = yield select(getPlans);
  const plan = plans[planId];
  const { zones } = plan.tiers.BASE;
  const [zoneName] = Object.entries(zones)[0];

  const diffedTiers = Object.entries(newTiers)
    .filter(([tier]) => !deletedTiers.includes(tier) && !addedTiers.includes(tier))
    .reduce((acc, [tierName, tierValues]) => {
      const existingTier = oldTiers[tierName] ?? {};
      const updatedSingleTier = Object.keys(existingTier).reduce((tierUpdates, tierKey) => {
        if (tierValues[tierKey] !== existingTier[tierKey]) {
          return { ...tierUpdates, [tierKey]: tierValues[tierKey] };
        }
        return tierUpdates;
      }, {});
      return isEmpty(updatedSingleTier) ? acc : { ...acc, [tierName]: { ...updatedSingleTier } };
    }, {});

  const actions = [
    ...Object.entries(diffedTiers).map(([tierName, tierValues]) =>
      tierActions.persistTierUpdate(planId, tierName, {
        ...tierValues,
        zone: zoneName,
      })
    ),
    ...deletedTiers.map((tierVolume) => tierActions.deleteTier(planId, tierVolume)),
  ];

  yield all([
    ...actions.map((a) => put(a)),
    ...addedTiers.map((tierVolume) =>
      call(
        createTier,
        planId,
        {
          ...newTiers[tierVolume],
          zone: zoneName,
          tier: `${tierVolume}DV`,
        },
        tierVolume
      )
    ),
  ]);
}
function* getGroupInfoForPlan(action) {
  const { groupid } = action.response.data;
  yield all([put(groupActions.getGroupById(groupid)), put(groupActions.getGroupMembers(groupid))]);
}

function* plansRootSaga() {
  yield all([
    createPlanHandler(),
    takeEvery(planActionTypes.UPDATE_PLAN_REQUEST, editPlanHandler),
    takeEvery(planActionTypes.GET_PLAN_SUCCESS, getGroupInfoForPlan),
    takeEvery(tierActionTypes.EDIT_TIERS, diffAndUpdateTiers),
  ]);
}

export default plansRootSaga;
