import * as carrierActions from 'common/store/entities/carriers/actions';
import { actions as deviceActions } from 'common/store/entities/devices';
import * as orgActions from 'common/store/entities/orgs/actions';
import * as planActions from 'common/store/entities/plans/actions';
import * as configActions from 'common/store/state/config/actions';
import * as modalActions from 'common/store/ui/modals/actions';
import ActiveReleaseFlagsPanel from 'components/organizations/ActiveReleaseFlagsPanel';
import BillingSummaryPanel from 'components/organizations/BillingSummaryPanel';
import CollaboratorsPanel from 'components/organizations/CollaboratorsPanel/';
import CustomerInfoPanel from 'components/organizations/CustomerInfoPanel';
import PackagesPanel from 'components/organizations/PackagesPanel';
import SearchBySimPanel from 'components/organizations/SearchBySimPanel';
import PlanSummaryPanel from 'components/plans/PlanSummaryPanel';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { getCurrentDeviceById } from 'src/common/selectors/entities/devices';
import { getCurrentDevicePlan, getPlansNoGroupZero } from 'src/common/selectors/entities/plans';
import OrgForm from 'src/components/forms/OrgForm';

import * as styles from './styles.module.scss';

const OrgSearch = ({
  activeDevices,
  carriers,
  currentOrgLoaded,
  device,
  disablePostPayForOrg,
  getActiveDevices,
  getAllPackages,
  getBalanceInfo,
  getBillingInfo,
  getCarriers,
  getDeviceAndPlanInfo,
  getOrgInfo,
  getOrgPackages,
  getOrgReleaseFlags,
  getOrgsPlanInfo,
  openModal,
  orgInfo,
  plans,
  searchByDeviceOrSimDetails,
}) => {
  const [carrier, setCarrier] = useState(null);
  const history = useHistory();
  const customerPanel = useRef();
  const match = useRouteMatch();
  const location = useLocation();
  const [releaseFlagsLoading, setReleaseFlagsLoading] = useState(false);

  const query = new URLSearchParams(location.search);
  const searchType = query.get('by') ?? 'org_details';
  const deviceId = query.get('deviceId');

  const getFirstKey = (obj) => Object.keys(obj ?? {})[0];

  const orgId = match.params.orgid;
  useEffect(() => {
    getAllPackages();

    if (deviceId) {
      getDeviceAndPlanInfo(deviceId);
    }

    if (orgId) {
      getActiveDevices(orgId);
      getBalanceInfo(orgId);
      getBillingInfo(orgId);
      getOrgInfo(orgId);
      getOrgPackages(orgId);
      getOrgReleaseFlags(orgId);
      getOrgsPlanInfo(orgId);
      if (customerPanel.current) {
        customerPanel.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [
    deviceId,
    getActiveDevices,
    getAllPackages,
    getBalanceInfo,
    getBillingInfo,
    getDeviceAndPlanInfo,
    getOrgInfo,
    getOrgPackages,
    getOrgReleaseFlags,
    getOrgsPlanInfo,
    orgId,
  ]);

  useEffect(() => {
    if (isEmpty(carriers)) {
      getCarriers();
    }
  }, [carriers, getCarriers]);

  useEffect(() => {
    if (!isEmpty(plans)) {
      const planData = Object.values(plans)[0];
      const { zones } = planData.tiers.BASE;
      const { carriers: carrierData } = Object.values(zones)[0];
      setCarrier(carriers[getFirstKey(carrierData)]);
    } else {
      setCarrier(false);
    }
  }, [carriers, plans]);

  const onViewClick = (newOrgId) => {
    history.push(`/workspace/organizations/search/${newOrgId}`);
    if (customerPanel.current) {
      customerPanel.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.organizations__container}>
      {searchType === 'sim_details' ? (
        <SearchBySimPanel searchByDeviceOrSimDetails={searchByDeviceOrSimDetails} device={device} />
      ) : (
        <OrgForm handleViewClick={onViewClick} buttonContent="View" />
      )}
      {orgInfo && (
        <>
          <h2 className={styles.organization_name_header}>{orgInfo.name}</h2>
          <div ref={customerPanel}>
            <CustomerInfoPanel orgInfo={orgInfo} carrier={carrier} activeDevices={activeDevices} />
          </div>
          <BillingSummaryPanel
            orgInfo={orgInfo}
            openModal={openModal}
            disablePostPayForOrg={disablePostPayForOrg}
          />
          <PackagesPanel
            orgInfo={orgInfo}
            openModal={openModal}
            setReleaseFlagsLoading={setReleaseFlagsLoading}
          />
          <ActiveReleaseFlagsPanel orgInfo={orgInfo} loading={releaseFlagsLoading} />
          <PlanSummaryPanel currentOrgLoaded={currentOrgLoaded} plans={plans} />
          <CollaboratorsPanel org={orgInfo} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const orgId = props.match.params.orgid;
  const query = new URLSearchParams(props.location.search);
  const searchType = query.get('by') ?? 'org_details';
  const deviceId = query.get('deviceId');

  return {
    orgInfo: state.entities.orgs.byId[orgId],
    plans:
      searchType === 'sim_details'
        ? getCurrentDevicePlan(state, deviceId)
        : getPlansNoGroupZero(state, props),
    currentOrgLoaded: state.entities.orgs.loaded[orgId],
    device: getCurrentDeviceById(state, deviceId),
    carriers: state.entities.carriers.byId,
    activeDevices: state.entities.devices.activeDevices,
  };
};

const mapDispatchToProps = {
  disablePostPayForOrg: orgActions.disablePostPayForOrg,
  getActiveDevices: deviceActions.getActiveDevices,
  getAllPackages: configActions.getAllPackages,
  getBalanceInfo: orgActions.getBalanceInfo,
  getBillingInfo: orgActions.getBillingInfo,
  getCarriers: carrierActions.getCarriers,
  getDeviceAndPlanInfo: deviceActions.getDeviceAndPlanInfo,
  getOrgInfo: orgActions.getOrgInfo,
  getOrgPackages: orgActions.getOrgPackages,
  getOrgReleaseFlags: orgActions.getOrgReleaseFlags,
  getOrgsPlanInfo: planActions.getOrgsPlanInfo,
  openModal: modalActions.openModal,
  searchByDeviceOrSimDetails: deviceActions.searchByDeviceOrSimDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgSearch);
