import { TextInputField } from '@hologram-dimension/text-input-field';
import { Form } from '@hologram-dimension/form';
import * as orgActions from 'common/store/entities/orgs/actions';
import SearchedOrgsList from 'components/common/lists/SearchedOrgsList';
import { throttle } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import * as styles from './SearchOrgsForm.module.scss';

class SearchOrgsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      orgQuery: '',
      queryIsOrgId: false,
    };
    this.submitQuery = throttle(this.submitQuery, 1000);
  }

  onOrgSearch = (e) => {
    const { value, name } = e.target;
    const queryIsOrgId = name === 'orgQueryById';
    this.setState({ orgQuery: value, queryIsOrgId });
    this.submitQuery();
  };

  onAddOrgClick = (e) => {
    const { addOrg, onOrgSelect, isEditPage } = this.props;
    const { orgId, orgName } = e.currentTarget.dataset;
    if (isEditPage) {
      addOrg(orgId);
    } else {
      onOrgSelect(orgId, orgName);
    }
  };

  advanceOrgsList = () => {
    const { searchData } = this.props;
    this.submitQuery(searchData.limit + 10);
  };

  submitQuery = (limit) => {
    const { orgQuery, queryIsOrgId } = this.state;
    const { searchOrgs } = this.props;
    if (orgQuery !== '') {
      searchOrgs(orgQuery, queryIsOrgId, limit);
    }
  };

  render() {
    const { orgQuery, queryIsOrgId } = this.state;
    const {
      clearSearchResults,
      group,
      groupIsNew,
      groupMembers,
      groupName,
      isActive,
      isComplete,
      isEditPage,
      panelRef,
      searchData,
      header,
    } = this.props;

    return (
      <div className={styles.outerContainer}>
        <div className={styles.panel__container} ref={panelRef}>
          {(isActive || isComplete || isEditPage) && (
            <>
              <div>
                <h2 className={styles.groupHeader}>
                  {header ??
                    (groupIsNew
                      ? `Add orgs to ${groupName}`
                      : `Add orgs to ${group.name ?? ''} (${parseInt(group.id, 10)})`)}
                </h2>
                <Form>
                  <TextInputField
                    name="orgQueryByName"
                    value={queryIsOrgId ? '' : orgQuery}
                    placeholder="Org name"
                    label="Search by name"
                    autocomplete="hologram-org-name"
                    inputProps={{ onChange: this.onOrgSearch }}
                  />
                  <TextInputField
                    name="orgQueryById"
                    value={queryIsOrgId ? orgQuery : ''}
                    placeholder="Org ID"
                    label="Find by ID"
                    autocomplete="hologram-org-id"
                    inputProps={{ onChange: this.onOrgSearch }}
                  />
                </Form>
              </div>
              <SearchedOrgsList
                orgsList={searchData.searched}
                continues={searchData.dataContinues}
                onPageAdvance={this.advanceOrgsList}
                onOrgClick={this.onAddOrgClick}
                disabledList={groupMembers}
                clearSearchResults={clearSearchResults}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchData: state.entities.orgs.temp,
});

const mapDispatchToProps = {
  clearSearchResults: orgActions.clearSearchResults,
  searchOrgs: orgActions.searchOrgs,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrgsForm);
