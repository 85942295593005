import { useParams } from 'react-router';
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Panel } from '@holokit/core';
import { getUser, updateUserGlobalRole } from 'common/api';

import GlobalRoleDropdown from 'components/users/GlobalRoleDropdown';

import * as styles from './styles.module.scss';

const SingleUser = ({ role }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();

  useEffect(() => {
    getUser(params.userid)
      .then((res) => {
        setUser(res?.data?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        const data = e?.response?.data;
        const errorMessage = data.error ? data.error : 'An error occured when fetching this user.';
        setError(errorMessage);
      });
  }, []);

  const updateRole = useCallback(
    (newRole) => {
      setIsLoading(true);
      updateUserGlobalRole(user.id, newRole)
        .then((res) => {
          setError(null);
          setUser(res?.data?.data);
          setIsLoading(false);
        })
        .catch((e) => {
          const data = e?.response?.data;
          const errorMessage = data.error
            ? data.error
            : 'An error occured when updating this user.';
          setError(errorMessage);
          setUser(null);
        });
    },
    [user]
  );

  return (
    <div className={styles.container}>
      <Panel isLoading={isLoading} title={user && `${user.first} ${user.last}`}>
        {user && (
          <dl className={styles.userInfo}>
            <dt className={styles.userInfoTitle}>Date Registered:</dt>
            <dd className={styles.userInfoDefinition}>{user.registered}</dd>
            <dt className={styles.userInfoTitle}>Email:</dt>
            <dd className={styles.userInfoDefinition}>{user.email}</dd>
            <dt className={styles.userInfoTitle}>Personal Org ID:</dt>
            <dd className={styles.userInfoDefinition}>{user.personal_org}</dd>
            <dt className={styles.userInfoTitle}>Global Role:</dt>
            <dd className={styles.userRole}>
              <GlobalRoleDropdown role={role} updateRole={updateRole} userCurrentRole={user.role} />
            </dd>
            <dt className={styles.userInfoTitle}>Partner ID:</dt>
            <dd className={styles.userInfoDefinition}>{user.partnerid}</dd>
            <dt className={styles.userInfoTitle}>Is Verified:</dt>
            <dd className={styles.userInfoDefinition}>{user.is_verified}</dd>
            <dt className={styles.userInfoTitle}>API Rate Limit:</dt>
            <dd className={styles.userInfoDefinition}>{user.apiratelimit}</dd>
          </dl>
        )}
        {error}
      </Panel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.user.user.role,
});

export default connect(mapStateToProps)(SingleUser);
