import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
};

export default function offersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_OFFERS_SUCCESS: {
      const { data } = action.response;
      const byId = data.reduce(
        (offers, currentOffer) => ({
          ...offers,
          [currentOffer.id]: currentOffer,
        }),
        {}
      );
      const loaded = data.reduce(
        (offers, currentOffer) => ({
          ...offers,
          [currentOffer.id]: true,
        }),
        {}
      );

      return {
        ...state,
        byId: { ...state.byId, ...byId },
        loaded: { ...state.loaded, ...loaded },
      };
    }

    default:
      return state;
  }
}
