const PREFIX = 'DEVICES_';

export const SEARCH_BY_SIM_DETAILS = `${PREFIX}SEARCH_BY_SIM_DETAILS`;

export const GET_DEVICE_REQUEST = `${PREFIX}GET_DEVICE_REQUEST`;
export const GET_DEVICE_SUCCESS = `${PREFIX}GET_DEVICE_SUCCESS`;
export const GET_DEVICE_ERROR = `${PREFIX}GET_DEVICE_ERROR`;

export const GET_DEVICE_BY_IMEI_REQUEST = `${PREFIX}GET_DEVICE_BY_IMEI_REQUEST`;
export const GET_DEVICE_BY_IMEI_SUCCESS = `${PREFIX}GET_DEVICE_BY_IMEI_SUCCESS`;
export const GET_DEVICE_BY_IMEI_ERROR = `${PREFIX}GET_DEVICE_BY_IMEI_BY_LINK_ERROR`;

export const GET_DEVICE_AND_PLAN_REQUEST = `${PREFIX}GET_DEVICE_AND_PLAN_REQUEST`;

export const GET_ACTIVE_DEVICES_REQUEST = `${PREFIX}GET_ACTIVE_DEVICES_REQUEST`;
export const GET_ACTIVE_DEVICES_SUCCESS = `${PREFIX}GET_ACTIVE_DEVICES_SUCCESS`;
export const GET_ACTIVE_DEVICES_ERROR = `${PREFIX}GET_ACTIVE_DEVICES_ERROR`;
