import React from 'react';
import { Switch, Route } from 'react-router';

import AuthedMainLayout from 'components/layouts/AuthedMain';
import Wizard from './Wizard';
import Quotes from './QuoteBuilder';
import FieldGuide from './FieldGuide';

const ProfilesPage = () => (
  <AuthedMainLayout
    navOptions={[
      {
        text: 'Wizard',
        pathname: '/workspace/profiles/wizard',
        isDefault: true,
      },
      {
        text: 'Quote Builder',
        pathname: '/workspace/profiles/quotes',
      },
      {
        text: 'Field Guide',
        pathname: '/workspace/profiles/field-guide',
      },
    ]}
  >
    <Switch>
      <Route path="/workspace/profiles/wizard" component={Wizard} />
      <Route path="/workspace/profiles/quotes" component={Quotes} />
      <Route path="/workspace/profiles/field-guide" component={FieldGuide} />
    </Switch>
  </AuthedMainLayout>
);

export default ProfilesPage;
