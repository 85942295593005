import React from 'react';

export const BASE_TITLE = 'Hologram Core';

export default function Base(props) {
  const { children } = props;

  return (
    <>
      {/* <Head> */}
      {/*   <title>{BASE_TITLE}</title> */}
      {/*   <link rel="icon" href="/favicon.ico" /> */}
      {/* </Head> */}
      {children}
    </>
  );
}
