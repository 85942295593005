// This file is an exact copy of the permissions utility file in Dashboard
// Found here: dashboard/src/js/common/utils/permissions.js

export const ACTIVATION = 'activation';
export const BILLING = 'billing';
export const CHANGEPLAN = 'changeplan';
export const DEACTIVATE = 'deactivate';
export const SBCONFIG = 'sbconfig';
export const SBTUNNEL = 'sbtunnel';
export const ORG_MANAGEMENT = 'org_management';

export function translateRoleToPermissions(role) {
  if (role === 'admin') {
    return getAllPerms();
  }
  if (role === 'manager') {
    return [ACTIVATION, CHANGEPLAN, DEACTIVATE, SBCONFIG, SBTUNNEL];
  }
  return [SBCONFIG, SBTUNNEL];
}

export function getAllPerms() {
  return [ORG_MANAGEMENT, ACTIVATION, BILLING, CHANGEPLAN, DEACTIVATE, SBCONFIG, SBTUNNEL];
}

export function translatePermissionsToRole(permissions) {
  if (permissions.includes(ORG_MANAGEMENT)) {
    return 'admin';
  }
  if (
    permissions.includes(ACTIVATION) &&
    permissions.includes(CHANGEPLAN) &&
    permissions.includes(DEACTIVATE)
  ) {
    return 'manager';
  }
  return 'editor';
}

export function globalRoleToHumanReadable(role) {
  if (role === 'shp') {
    return 'Ship Admin';
  }
  if (role === 'adm') {
    return 'Admin';
  }
  if (role === 'hdm') {
    return 'Hyper Admin';
  }
  return 'Non-Admin';
}
