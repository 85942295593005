import React from 'react';
import { Panel } from '@holokit/core';

import * as styles from './styles.module.scss';

const CustomerInfoPanel = ({ orgInfo, carrier, activeDevices }) => {
  const owner = orgInfo?.users?.find((user) => user.id === orgInfo.ownerid);

  let hasMfa = false;
  if (orgInfo?.releaseFlags) {
    hasMfa = Object.values(orgInfo.releaseFlags).includes('mfa');
  }

  return (
    <Panel title="Customer information">
      {orgInfo?.id && (
        <div className={styles.customer_info_container}>
          <div className={styles.column}>
            <table>
              <tbody>
                <tr>
                  <th>Dashboard owner </th>
                  <td>{`${owner.first} ${owner.last} (${owner.email})`}</td>
                </tr>
                <tr>
                  <th>User ID </th>
                  <td>{owner.id}</td>
                </tr>
                {carrier && (
                  <tr>
                    <th>SIM provider </th>
                    <td>{carrier.name}</td>
                  </tr>
                )}
                <tr>
                  <th>Active device count </th>
                  <td>{activeDevices}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.column}>
            <table>
              <tbody>
                <tr>
                  <th>Organization ID </th>
                  <td>{orgInfo.id}</td>
                </tr>
                <tr>
                  <th>Organization name </th>
                  <td>{orgInfo.name}</td>
                </tr>
                <tr>
                  <th>Multi-factor authentication </th>
                  <td>{hasMfa ? 'Enabled' : 'Disabled'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Panel>
  );
};

export default CustomerInfoPanel;
