import * as actionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const getAllPackages = () => ({
  type: actionTypes.GET_ALL_PACKAGES_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_ALL_PACKAGES_SUCCESS,
  errorType: actionTypes.GET_ALL_PACKAGES_ERROR,
  url: '/productConfiguration/packages',
});
