import { all, put, take, takeEvery } from 'redux-saga/effects';

import * as userActionTypes from 'common/store/entities/users/actionTypes';
import * as userActions from 'common/store/entities/users/actions';

import * as orgActions from 'common/store/entities/orgs/actions';
import * as errorActions from 'common/store/ui/errors/actions';

function* getUserOrgDetailsByEmail(action) {
  const { email } = action;
  yield put(userActions.searchUsersByEmail(email));
  const returnAction = yield take(userActionTypes.SEARCH_USER_BY_EMAIL_SUCCESS);
  const { data } = returnAction.response;

  // data comes back as an array, so grab the first element if it's here
  if (data[0]) {
    const { id } = data[0];
    yield put(orgActions.searchForOrgsByUserId(id));
  } else {
    yield put(errorActions.throwOrgError("We can't find a match with that email."));
  }
}

function* usersRootSaga() {
  yield all([takeEvery(userActionTypes.GET_ORGS_BY_USER_EMAIL, getUserOrgDetailsByEmail)]);
}

export default usersRootSaga;
