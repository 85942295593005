import update from 'immutability-helper';

import { toObjectByIds, toBooleanObjectByIds } from 'src/common/utils/objectFormatter/index';
import * as orgActionTypes from 'common/store/entities/orgs/actionTypes';

import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
};

export default function userEntityReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SEARCH_USER_BY_EMAIL_SUCCESS: {
      const { data } = action.response;

      const users = toObjectByIds(data, ['orgs']);
      const loadedUsers = toBooleanObjectByIds(data);

      return update(state, {
        byId: { $merge: users },
        loaded: { $merge: loadedUsers },
      });
    }

    case orgActionTypes.SEARCH_FOR_ORG_BY_USER_ID_SUCCESS: {
      const { userId, response } = action;
      const { data } = response;
      const ids = data.map((org) => org.id);
      return update(state, {
        byId: {
          [userId]: {
            relationships: {
              orgs: { $push: ids },
            },
          },
        },
      });
    }

    default:
      return state;
  }
}
