import * as actionTypes from './actionTypes';

export const clearDeviceSearchState = () => ({
  type: actionTypes.CLEAR_DEVICE_SEARCH_STATE,
});

export const clearOrgSearchState = () => ({
  type: actionTypes.CLEAR_ORG_SEARCH_STATE,
});

export const throwDeviceError = (error) => ({
  type: actionTypes.THROW_DEVICE_ERROR,
  error,
});

export const throwOrgError = (error) => ({
  type: actionTypes.THROW_ORG_ERROR,
  error,
});

export const throwPostPayError = (error) => ({
  type: actionTypes.THROW_POSTPAY_ERROR,
  error,
});
