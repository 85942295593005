import React from 'react';
import { ComplexIcon } from '@holokit/core';
import { useLocation, Link } from 'react-router';
import { connect } from 'react-redux';

import * as styles from './styles.module.scss';

const CreateOrEditPlanSuccess = ({ createOrEditPlanSyncState }) => {
  const location = useLocation();
  const isEditPage = location.pathname.includes('edit');
  const text = isEditPage ? 'Edited' : 'Created';
  const { planId } = createOrEditPlanSyncState;
  const { planName } = createOrEditPlanSyncState;

  return (
    <div className={styles.outsideContainer}>
      <h1>Plan {text}!</h1>
      <div className={styles.logoBox}>
        <ComplexIcon name="logo-rainbow" />
      </div>
      <h3>
        Plan ID {planId} ({planName}) was successfully {text.toLowerCase()}!
      </h3>
      {!isEditPage && <Link to="/workspace/plans/create">Back to plan creation</Link>}
      {isEditPage && <Link to="/workspace/plans/search">Back to plans searching</Link>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  createOrEditPlanSyncState: state.state.plans.createOrEditPlan,
});

export default connect(mapStateToProps)(CreateOrEditPlanSuccess);
