import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import LoginPage from 'pages/login';
import ModalContainer from 'components/modals';
import * as userActions from 'common/store/user/actions';

import Authed from 'components/layouts/Authed';
import history from '../createHistory';

import '../scss/entry.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
    props.getUserInfo();
  }

  render() {
    return (
      <>
        <ModalContainer />
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/workspace" component={Authed} />
            <Route path="/login" component={LoginPage} />
            <Route exact path="/" render={() => <Redirect to="/login" />} />
          </Switch>
        </ConnectedRouter>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserInfo: userActions.getUserInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
