import React from 'react';

const HelpPanel = () => (
  <div>
    <h3>Name</h3>
    Name is used as a descriptive identifier for this plan. Often, it is the name of the org it's
    been created for, or the region of the world that it works in.
    <h3>Data</h3>
    Data is the amount of prepaid data (in bytes). If the plan is PayGo, use 0.
    <h2>Zones</h2>
    <h3>Name</h3>A name for the particular cellular zone. Typically, these are either increasing
    numbers (1, 2, 3), or the region of the world for this particular zone (usa, global, brazil).
    <h3>MRC / Device</h3>
    Monthly recurring cost per device.
    <h3>Cost / SMS</h3>
    Cost Per SMS message sent
    <h3>Cost / Overage</h3>
    Cost (in MB) for overage in a prepaid plan, which is also the overall cost of data for PayGo
    plans.
  </div>
);

export default HelpPanel;
