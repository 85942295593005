import _ from 'lodash';
import { all, call, put, race, take, takeEvery } from 'redux-saga/effects';

import {
  actionTypes as deviceActionTypes,
  actions as deviceActions,
} from 'common/store/entities/devices';
import {
  actionTypes as linkActionTypes,
  actions as linkActions,
} from 'common/store/entities/links';
import { actionTypes as errorActionTypes, actions as errorActions } from 'common/store/ui/errors';
import * as planActions from 'common/store/entities/plans/actions';

function* getAllSimDetailsByParam(action) {
  const { simValues, history } = action;
  const simValue = _.pickBy(simValues, (value) => !!value);
  let deviceId;

  if (simValue.linkId) {
    yield put(linkActions.getCellularLink(simValue.linkId));
    const linkAction = yield take(linkActionTypes.GET_LINK_SUCCESS);
    deviceId = linkAction.response.data[0]?.deviceid;
  } else if (simValue.deviceId) {
    const { deviceId: id } = simValue;
    deviceId = id;
  } else if (simValue.iccid) {
    yield put(linkActions.getLinkByIccid(simValue.iccid));
    const linkAction = yield take(linkActionTypes.GET_LINK_BY_ICCID_SUCCESS);
    deviceId = linkAction.response.data[0]?.deviceid;
  } else if (simValue.imsi) {
    yield put(linkActions.getLinkByImsi(simValue.imsi));
    const linkAction = yield take(linkActionTypes.GET_LINK_BY_IMSI_SUCCESS);
    deviceId = linkAction.response.data[0]?.deviceid;
  } else if (simValue.imei) {
    yield put(deviceActions.getDeviceByImei(simValue.imei));
    const deviceAction = yield take(deviceActionTypes.GET_DEVICE_BY_IMEI_SUCCESS);
    deviceId = deviceAction.response.data[0]?.id;
  }

  if (deviceId) {
    const { orgId, planId, linkId } = yield getDeviceInfo(deviceId);
    yield all([put(planActions.getPlan(planId)), put(linkActions.getCellularLink(linkId))]);
    yield call(
      history.push,
      `/workspace/organizations/search/${orgId}?by=sim_details&deviceId=${deviceId}`
    );
  }
}

function* getDeviceInfo(deviceId) {
  yield put(deviceActions.getDevice(deviceId));
  const deviceAction = yield take(deviceActionTypes.GET_DEVICE_SUCCESS);
  const { data } = deviceAction.response;
  const { orgid: orgId } = data;
  const { cellular } = data?.links;
  if (!cellular.length) {
    yield put(errorActions.throwDeviceError('No links can be found with this device.'));
  } else {
    const planId = cellular[0].plan.id;
    const linkId = cellular[0].id;
    return { orgId, planId, linkId };
  }
}

function* getDeviceAndPlan(action) {
  const { deviceId } = action;
  const { planId } = yield getDeviceInfo(deviceId);
  yield put(planActions.getPlan(planId));
}

function* getSimDetailsHandler() {
  while (true) {
    const action = yield take(deviceActionTypes.SEARCH_BY_SIM_DETAILS);
    const [success, ...rest] = yield race([
      call(getAllSimDetailsByParam, action),
      take(deviceActionTypes.GET_DEVICE_ERROR),
      take(deviceActionTypes.GET_DEVICE_BY_IMEI_ERROR),
      take(linkActionTypes.GET_LINK_ERROR),
      take(linkActionTypes.GET_LINK_BY_ICCID_ERROR),
      take(linkActionTypes.GET_LINK_BY_IMSI_ERROR),
      take(errorActionTypes.THROW_DEVICE_ERROR),
    ]);

    if (!success) {
      const error = rest.find((errorResponse) => !!errorResponse);
      yield put(
        errorActions.throwDeviceError(
          error?.response || "We didn't find a match. Try another search?"
        )
      );
    }
  }
}

function* devicesRootSaga() {
  yield all([
    getSimDetailsHandler(),
    takeEvery(deviceActionTypes.GET_DEVICE_AND_PLAN_REQUEST, getDeviceAndPlan),
  ]);
}

export default devicesRootSaga;
