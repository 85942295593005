import { omit } from 'lodash';

import * as planActionTypes from 'common/store/entities/plans/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {};

export default function plansReducer(state = initialState, action) {
  switch (action.type) {
    case planActionTypes.CREATE_OR_EDIT_PLAN_SUCCESS: {
      return {
        ...state,
        createOrEditPlan: {
          planId: action.planId,
          planName: action.name,
        },
      };
    }

    case actionTypes.THROW_ERROR: {
      return {
        ...state,
        createOrEditPlan: {
          error: action.error,
        },
      };
    }

    case actionTypes.CLEAR_PLAN_CREATE_OR_EDIT_STATE: {
      return omit(state, 'createOrEditPlan');
    }

    case planActionTypes.GET_ORG_PLANS_SUCCESS: {
      const { continues } = action.response;

      return {
        ...state,
        dataContinues: continues,
      };
    }

    default:
      return state;
  }
}
