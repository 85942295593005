import { omit } from 'lodash';

import * as planActionTypes from 'common/store/entities/plans/actionTypes';
import * as orgActionTypes from 'common/store/entities/orgs/actionTypes';
import * as userActionTypes from 'common/store/entities/users/actionTypes';
import * as deviceActionTypes from 'common/store/entities/devices/actionTypes';
import * as linkActionTypes from 'common/store/entities/links/actionTypes';

const initialState = {};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case planActionTypes.PERSIST_PLAN_REQUEST: {
      return {
        ...state,
        [action.type]: true,
      };
    }

    case planActionTypes.CREATE_PLAN_SUCCESS: {
      return omit(state, planActionTypes.PERSIST_PLAN_REQUEST);
    }

    case orgActionTypes.SEARCH_ORG_REQUEST: {
      return {
        ...state,
        [action.type]: true,
      };
    }

    case orgActionTypes.SEARCH_ORG_SUCCESS: {
      return omit(state, orgActionTypes.SEARCH_ORG_REQUEST);
    }

    case orgActionTypes.SEARCH_ORG_ERROR: {
      return omit(state, orgActionTypes.SEARCH_ORG_REQUEST);
    }

    case userActionTypes.SEARCH_USER_BY_EMAIL_REQUEST: {
      return {
        ...state,
        [action.type]: true,
      };
    }

    case userActionTypes.SEARCH_USER_BY_EMAIL_SUCCESS: {
      return omit(state, userActionTypes.SEARCH_USER_BY_EMAIL_REQUEST);
    }

    case userActionTypes.SEARCH_USER_BY_EMAIL_ERROR: {
      return omit(state, userActionTypes.SEARCH_USER_BY_EMAIL_REQUEST);
    }

    case deviceActionTypes.GET_DEVICE_REQUEST: {
      return {
        ...state,
        [action.type]: true,
      };
    }

    case deviceActionTypes.GET_DEVICE_SUCCESS: {
      return omit(state, deviceActionTypes.GET_DEVICE_REQUEST);
    }

    case deviceActionTypes.GET_DEVICE_ERROR: {
      return omit(state, deviceActionTypes.GET_DEVICE_REQUEST);
    }

    case deviceActionTypes.GET_DEVICE_BY_IMEI_REQUEST: {
      return {
        ...state,
        [action.type]: true,
      };
    }

    case deviceActionTypes.GET_DEVICE_BY_IMEI_SUCCESS: {
      return omit(state, deviceActionTypes.GET_DEVICE_BY_IMEI_REQUEST);
    }

    case deviceActionTypes.GET_DEVICE_BY_IMEI_ERROR: {
      return omit(state, deviceActionTypes.GET_DEVICE_BY_IMEI_REQUEST);
    }

    case linkActionTypes.GET_LINK_REQUEST: {
      return {
        ...state,
        [action.type]: true,
      };
    }

    case linkActionTypes.GET_LINK_SUCCESS: {
      return omit(state, linkActionTypes.GET_LINK_REQUEST);
    }

    case linkActionTypes.GET_LINK_ERROR: {
      return omit(state, linkActionTypes.GET_LINK_REQUEST);
    }

    case linkActionTypes.GET_LINK_BY_ICCID_REQUEST: {
      return {
        ...state,
        [action.type]: true,
      };
    }

    case linkActionTypes.GET_LINK_BY_ICCID_SUCCESS: {
      return omit(state, linkActionTypes.GET_LINK_BY_ICCID_REQUEST);
    }

    case linkActionTypes.GET_LINK_BY_ICCID_ERROR: {
      return omit(state, linkActionTypes.GET_LINK_BY_ICCID_REQUEST);
    }

    case linkActionTypes.GET_LINK_BY_IMSI_REQUEST: {
      return {
        ...state,
        [action.type]: true,
      };
    }

    case linkActionTypes.GET_LINK_BY_IMSI_SUCCESS: {
      return omit(state, linkActionTypes.GET_LINK_BY_IMSI_REQUEST);
    }

    case linkActionTypes.GET_LINK_BY_IMSI_ERROR: {
      return omit(state, linkActionTypes.GET_LINK_BY_IMSI_REQUEST);
    }

    default:
      return state;
  }
};

export default uiReducer;
