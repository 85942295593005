import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import * as modalActions from 'common/store/ui/modals/actions';
import ModalInstances from './ModalInstances';
import * as styles from './styles.module.scss';

export const ModalContainer = (props) => {
  const { openModal, closeModal, openModalName, modalProps } = props;

  const sharedModalProps = {
    closeModal,
    openModal,
    ...modalProps,
  };

  let ModalContent;
  if (openModalName) {
    ModalContent = ModalInstances[openModalName];
  }

  return (
    <div
      className={classnames(styles.container, {
        [styles.hidden]: !openModalName,
      })}
    >
      {openModalName && (
        <div className={styles.innerContainer}>
          <ModalContent {...sharedModalProps} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  openModalName: state.ui.modal.openModalName,
  modalProps: state.ui.modal.modalProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal: modalActions.openModal,
      closeModal: modalActions.closeModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
