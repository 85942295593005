import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import React, { useEffect, useMemo, memo } from 'react';

import * as styles from './styles.module.scss';

const OrgRow = memo(
  ({ org, onOrgClick, disabledList, selectButtonContent }) => (
    <tr>
      <td className={styles.orgCell}>
        {org.name} ({org.id})
      </td>
      <td>
        <Button
          variant="secondary"
          size="small"
          onClick={onOrgClick}
          data-org-id={org.id}
          data-org-name={org.name}
          disabled={disabledList && disabledList.some((groupOrg) => groupOrg.id === org.id)}
        >
          {selectButtonContent}
        </Button>
      </td>
    </tr>
  ),
  (prev, next) => prev.org.id !== next.org.id
);

const SearchedOrgsList = ({
  orgsList,
  continues,
  success,
  onPageAdvance,
  onOrgClick,
  disabledList,
  selectButtonContent,
  clearSearchResults,
  searchOrgError,
  showPersonalOrgsAsOwnList,
}) => {
  // cleanup to clear search results
  useEffect(
    () => () => {
      clearSearchResults();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [orgs, personalOrgs] = useMemo(() => {
    if (!showPersonalOrgsAsOwnList) {
      return [orgsList];
    }
    return [orgsList.filter((org) => !org.is_personal), orgsList.filter((org) => org.is_personal)];
  }, [orgsList, showPersonalOrgsAsOwnList]);

  // searching by org name will return empty data array
  // searching by orgid and user email will throw an error
  return (!orgsList.length && success) || searchOrgError?.error ? (
    <div className={styles.noResults}>
      <BannerNotification variant="invalid">
        We didn&apos;t find a match. Try another search?
      </BannerNotification>
    </div>
  ) : (
    <div>
      <div className={styles.outerContainer}>
        <div className={styles.listContainer}>
          {showPersonalOrgsAsOwnList && <div className={styles.label}>Shared Orgs</div>}
          <table className={styles.resultsTable}>
            <tbody>
              {orgs.map((org) => (
                <OrgRow
                  key={org.id}
                  org={org}
                  onOrgClick={onOrgClick}
                  disabledList={disabledList}
                  selectButtonContent={selectButtonContent}
                />
              ))}
            </tbody>
          </table>
        </div>
        {showPersonalOrgsAsOwnList && (
          <div className={styles.listContainer}>
            <div className={styles.label}>Personal Orgs</div>
            <table className={styles.resultsTable}>
              <tbody>
                {personalOrgs.map((org) => (
                  <OrgRow
                    key={org.id}
                    org={org}
                    onOrgClick={onOrgClick}
                    disabledList={disabledList}
                    selectButtonContent={selectButtonContent}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {continues && (
        <div className={styles.viewMoreButton}>
          <Button size="small" onClick={onPageAdvance}>
            View More
          </Button>
        </div>
      )}
    </div>
  );
};

SearchedOrgsList.defaultProps = {
  selectButtonContent: 'Add',
};

export default SearchedOrgsList;
