import { Route } from 'react-router';
import React, { useState, useCallback } from 'react';
import { InputGroup, Panel } from '@holokit/core';
import { getOrgUsers } from 'common/api';

import { translatePermissionsToRole } from 'common/utils/permissionsAndRoles';

import * as styles from './styles.module.scss';

const AllUsersPanel = () => {
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orgid, setOrgid] = useState('');
  const [users, setUsers] = useState([]);

  const handleChange = useCallback((e) => {
    const { value } = e.target;

    setInputValue(value);
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    if (inputValue === orgid) {
      return;
    }
    setIsLoading(true);
    if (inputValue === '') {
      setData([], '');
    } else {
      getOrgUsers(inputValue)
        .then((res) => {
          const data = res?.data?.data;
          setData(data.users, '');
        })
        .catch((err) => {
          const data = err?.response?.data;
          const errorMessage = data.error ? data.error : 'An error occured.';
          setData([], errorMessage);
        });
    }
  });

  const setData = useCallback((newUsers, newError) => {
    setError(newError);
    setIsLoading(false);
    setOrgid(inputValue);
    setUsers(newUsers);
  });

  const isEmpty = orgid === '' && !users.length;
  const noResults = orgid !== '' && !users.length;
  const hasError = error.length;

  let panelContent;
  if (hasError) {
    panelContent = <div className={styles.messageError}>{error}</div>;
  } else if (!hasError && isEmpty) {
    panelContent = <div className={styles.message}>Search for an Org by ID to see Users.</div>;
  } else if (!hasError && noResults) {
    panelContent = <div className={styles.message}>No users found.</div>;
  } else {
    panelContent = (
      <table className="Table User">
        <thead className="Table__header Table__header--sticky">
          <tr>
            <th className="Table__cell Table__cell--header">
              <div className="Table__cell-content">Name</div>
            </th>
            <th className="Table__cell Table__cell--header">
              <div className="Table__cell-content">ID</div>
            </th>
            <th className="Table__cell Table__cell--header">
              <div className="Table__cell-content">Email</div>
            </th>
            <th className="Table__cell Table__cell--header">
              <div className="Table__cell-content">Dashboard Role</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <Route
              key={`${index}${user.last}${user.id}`}
              render={({ history }) => (
                <tr
                  className={styles.user}
                  onClick={() => history.push(`/workspace/users/${user.id}`)}
                >
                  <td className="Table__cell">
                    <div className="Table__cell-content">{`${user.first} ${user.last}`}</div>
                  </td>
                  <td className="Table__cell">
                    <div className="Table__cell-content">{user.id}</div>
                  </td>
                  <td className="Table__cell">
                    <div className="Table__cell-content">
                      <a href={`mailto:${user.email}`} className="User__email">
                        {user.email}
                      </a>
                    </div>
                  </td>
                  <td className="Table__cell">
                    <div className="Table__cell-content">
                      {translatePermissionsToRole(user.permissions)}
                    </div>
                  </td>
                </tr>
              )}
            />
          ))}
        </tbody>
      </table>
    );
  }
  return (
    <div className={styles.container}>
      <Panel
        isLoading={isLoading}
        headerButtons={
          <form key="orgIdForm" className={styles.form} onSubmit={handleSubmit}>
            <InputGroup
              placeholder="Org ID"
              iconRight="Search"
              iconRightOnClick={handleSubmit}
              onChange={handleChange}
              round
              value={inputValue}
            />
          </form>
        }
        noBodyPadding
        title={!isEmpty && `Users in Org ID: ${orgid}`}
      >
        {panelContent}
      </Panel>
    </div>
  );
};

export default AllUsersPanel;
