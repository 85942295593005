import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { Panel, InputGroup, FormGroup, Button, Icon } from '@holokit/core';
import * as errorActions from 'common/store/ui/errors/actions';

import * as deviceActionTypes from 'common/store/entities/devices/actionTypes';
import * as linkActionTypes from 'common/store/entities/links/actionTypes';

import * as styles from './styles.module.scss';

const SearchBySimPanel = ({
  searchByDeviceOrSimDetails,
  device,
  loading,
  searchDeviceError,
  clearErrors,
}) => {
  useEffect(
    () => () => {
      clearErrors();
    },
    [clearErrors]
  );

  const history = useHistory();
  const [values, setValues] = useState({
    iccid: '',
    imsi: '',
    imei: '',
    linkId: '',
    deviceId: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchByDeviceOrSimDetails(values, history);
  };

  const handleResetValues = () => {
    history.push('/workspace/organizations/search?by=sim_details');
    setValues({
      iccid: '',
      imsi: '',
      imei: '',
      linkId: '',
      deviceId: '',
    });
    clearErrors();
  };

  return (
    <Panel
      title="Find a SIM"
      isLoading={
        loading[deviceActionTypes.GET_DEVICE_REQUEST] ||
        loading[deviceActionTypes.GET_DEVICE_BY_IMEI_REQUEST] ||
        loading[linkActionTypes.GET_LINK_REQUEST] ||
        loading[linkActionTypes.GET_LINK_BY_ICCID_REQUEST] ||
        loading[linkActionTypes.GET_LINK_BY_IMSI_REQUEST]
      }
    >
      {searchDeviceError?.error && (
        <div className={styles.error}>
          <Icon
            name="Circle--alert"
            size="major"
            svgProps={{ style: { fill: '#099FC3', opacity: '0.4' } }}
          />
          <div className={styles.errorText}>{searchDeviceError.error}</div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.formColumn}>
          <form onSubmit={handleSubmit}>
            <FormGroup label="ICCID" labelFor="iccid">
              <InputGroup
                value={device?.links.cellular[0].sim ?? values.iccid}
                id="iccid"
                name="iccid"
                onChange={handleChange}
                disabled={values.imsi || values.imei || values.deviceId || values.linkId}
              />
            </FormGroup>
            <FormGroup label="IMSI" labelFor="imsi">
              <InputGroup
                value={device?.links.cellular[0].imsi ?? values.imsi}
                id="imsi"
                name="imsi"
                onChange={handleChange}
                disabled={values.iccid || values.imei || values.deviceId || values.linkId}
              />
            </FormGroup>
          </form>
        </div>
        <div className={styles.formColumn}>
          <form onSubmit={handleSubmit}>
            <FormGroup label="IMEI" labelFor="imei">
              <InputGroup
                value={device?.imei ?? values.imei}
                id="imei"
                name="imei"
                onChange={handleChange}
                disabled={values.iccid || values.imsi || values.deviceId || values.linkId}
              />
            </FormGroup>
            <FormGroup label="Device ID" labelFor="deviceId">
              <InputGroup
                value={device?.id ?? values.deviceId}
                id="deviceId"
                name="deviceId"
                onChange={handleChange}
                disabled={values.iccid || values.imsi || values.imei || values.linkId}
              />
            </FormGroup>
          </form>
        </div>
        <div className={styles.formColumn}>
          <form onSubmit={handleSubmit}>
            <FormGroup label="Link ID" labelFor="linkId" classes={styles.link}>
              <InputGroup
                value={device?.links.cellular[0].id ?? values.linkId}
                id="linkId"
                name="linkId"
                onChange={handleChange}
                disabled={values.iccid || values.imsi || values.imei || values.deviceId}
              />
            </FormGroup>
          </form>
          {device && (
            <div className={styles.stateContainer}>
              <div>State:</div>
              <div>{device?.links.cellular[0]?.state}</div>
            </div>
          )}
        </div>
        <div className={styles.formColumn}>
          <div className={styles.buttonContainer}>
            <Button type="secondary" onClick={handleResetValues}>
              Reset Values
            </Button>
            <Button
              type="primary"
              buttonProps={{ type: 'submit' }}
              onClick={handleSubmit}
              disabled={
                !(values.iccid || values.imsi || values.imei || values.deviceId || values.linkId)
              }
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  loading: state.ui.loading,
  searchDeviceError: state.ui.error.searchDevice,
});

const mapDispatchToProps = {
  clearErrors: errorActions.clearDeviceSearchState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBySimPanel);
