import React from 'react';
import ZoneTable from '../ZoneTable';

import * as styles from './styles.module.scss';

export default (props) => {
  const { sortedTiers } = props;

  return (
    <table className={styles.TierTableSubTable}>
      <tbody>
        {sortedTiers.map((tier, index) => (
          <tr key={index}>
            <td className={`${styles.TierTableCell} ${styles.TierColumn}`}>{tier[0]}</td>

            <td className={styles.TierTableCell}>
              <ZoneTable zones={tier[1].zones} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
