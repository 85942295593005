import { toObjectByIds, toBooleanObjectByIds } from 'src/common/utils/objectFormatter/index';

import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
};

export default function tiersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_TIER_VOLUME_TYPES_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...toObjectByIds(action.response.data, null, 'name'),
        },
        loaded: {
          ...state.loaded,
          ...toBooleanObjectByIds(action.response.data, 'name'),
        },
      };
    }

    default:
      return state;
  }
}
