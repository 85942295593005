import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { Panel } from '@hologram-dimension/panel';
import { TextInputField } from '@hologram-dimension/text-input-field';
import * as orgActions from 'common/store/entities/orgs/actions';
import * as planActions from 'common/store/entities/plans/actions';
import SearchedOrgsList from 'components/common/lists/SearchedOrgsList';
import React from 'react';
import { connect } from 'react-redux';
import TableRow from './Components/TableRow';

import * as styles from './styles.module.scss';

class SearchPanel extends React.Component {
  constructor() {
    super();
    this.state = {
      planId: '',
      planName: '',
      orgName: '',
      orgId: '',
      searchedOrgId: '',
      searchedOnce: null,
      selectedOrg: null,
    };
  }

  handleInputChange = (e) => {
    let fieldName = e.target.name;
    if (fieldName === 'orgNumber') {
      fieldName = 'orgId';
    } else if (fieldName === 'planNumber') {
      fieldName = 'planId';
    }
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  handleSearch = (e) => {
    const { searchPlans, getOrgInfo } = this.props;
    const { planName, orgId, orgName, planId } = this.state;

    e.preventDefault();

    if (orgName) {
      this.submitOrgQuery();
    } else {
      searchPlans(orgId, planName, planId);
      this.setState({ searchedOnce: true, selectedOrg: null });
    }
    if (orgId) {
      getOrgInfo(orgId);
      this.setState({ searchedOrgId: orgId });
    } else {
      this.setState({ searchedOrgId: '' });
    }
  };

  advanceOrgsList = () => {
    const { orgsSearchData } = this.props;
    this.submitOrgQuery(orgsSearchData.limit + 10);
  };

  searchOrgPlans = (e) => {
    const { searchPlans } = this.props;
    const { orgId, orgName } = e.currentTarget.dataset;
    searchPlans(orgId);
    this.setState({ searchedOnce: true, selectedOrg: { orgId, orgName } });
  };

  submitOrgQuery = (limit) => {
    const { orgName } = this.state;
    const { searchOrgs } = this.props;
    if (orgName !== '') {
      searchOrgs(orgName, false, limit);
    }
  };

  render() {
    const { searchResults, orgsSearchData, clearSearchResults, orgsById } = this.props;
    const { planName, orgName, orgId, searchedOrgId, planId, searchedOnce, selectedOrg } =
      this.state;

    return (
      <div className={styles.searchPanelWrapper}>
        <Panel header="Hologram Plans">
          <div className={styles.customPlansContainer}>
            <div className={styles.searchForm}>
              <h3 className={styles.searchFormHeader}>Search Existing Plans</h3>
              <Form
                onSubmit={this.handleSearch}
                footerActions={
                  <Button type="submit" disabled={!planName && !orgName && !orgId && !planId}>
                    {orgName ? 'Search Orgs' : 'Search Plans'}
                  </Button>
                }
              >
                <div className={styles.input_group}>
                  <TextInputField
                    name="planNumber"
                    id="planNumber"
                    type="number"
                    value={planId}
                    placeholder="Plan ID"
                    label="Plan ID"
                    autocomplete="hologram-plan-id"
                    inputProps={{ onChange: this.handleInputChange }}
                    disabled={orgName !== '' || orgId !== '' || planName !== ''}
                  />
                </div>
                <div className={styles.input_group}>
                  <TextInputField
                    name="planName"
                    id="planName"
                    type="text"
                    value={planName}
                    placeholder="Plan name"
                    label="Plan name"
                    autocomplete="hologram-plan-name"
                    inputProps={{ onChange: this.handleInputChange }}
                    disabled={orgName !== '' || orgId !== '' || planId !== ''}
                  />
                </div>
                <div className={styles.input_group}>
                  <TextInputField
                    name="orgName"
                    id="orgName"
                    type="text"
                    value={orgName}
                    placeholder="Org name"
                    label="Org name"
                    autocomplete="hologram-org-name"
                    inputProps={{ onChange: this.handleInputChange }}
                    disabled={planName !== '' || orgId !== '' || planId !== ''}
                  />
                </div>
                <div className={styles.input_group}>
                  <TextInputField
                    name="orgNumber"
                    id="orgNumber"
                    type="text"
                    value={orgId}
                    placeholder="Org ID"
                    label="Org ID"
                    autocomplete="hologram-org-id"
                    inputProps={{ onChange: this.handleInputChange }}
                    disabled={planName !== '' || orgName !== '' || planId !== ''}
                  />
                </div>
              </Form>
            </div>
            {orgsSearchData.searched && (
              <SearchedOrgsList
                orgsList={orgsSearchData.searched}
                continues={orgsSearchData.dataContinues}
                onPageAdvance={this.advanceOrgsList}
                onOrgClick={this.searchOrgPlans}
                selectButtonContent="View Plans"
                clearSearchResults={clearSearchResults}
              />
            )}
          </div>
        </Panel>

        {searchResults.length ? (
          <Panel header={orgsById[searchedOrgId]?.name ?? selectedOrg?.orgName ?? ''}>
            <table className="Table Table--short">
              <thead className="Table__header Table__header--sticky">
                <tr>
                  <th className={styles.headerCell}>PLAN ID</th>
                  <th className={styles.headerCell}>NAME</th>
                  <th className={styles.headerCell}>GROUP ID</th>
                  <th className={styles.headerCell}>MB</th>
                  <th className={`${styles.headerCell} ${styles.TierColumn}`}>TIER</th>
                  <th className={`${styles.headerCell} ${styles.ZoneColumn}`}>ZONE</th>
                  <th className={`${styles.headerCell} ${styles.MrcColumn}`}>MRC</th>
                  <th className={`${styles.headerCell} ${styles.SmsColumn}`}>SMS</th>
                  <th className={`${styles.headerCell} ${styles.OverageColumn}`}>OVERAGE</th>
                  <th className={`${styles.headerCell} ${styles.CarriersColumn}`}>CARRIERS</th>
                  <th className={styles.editHeader} aria-label="Make Edit" />
                </tr>
              </thead>
              <tbody>
                {searchResults?.map((result) => (
                  <TableRow result={result} key={result.id} />
                ))}
              </tbody>
            </table>
          </Panel>
        ) : (
          searchedOnce && 'No results found'
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchResults: state.entities.plans.temp.searched,
  orgsSearchData: state.entities.orgs.temp,
  orgsById: state.entities.orgs.byId,
});

const mapDispatchToProps = {
  clearSearchResults: orgActions.clearSearchResults,
  searchPlans: planActions.searchPlans,
  getPlan: planActions.getPlan,
  searchOrgs: orgActions.searchOrgs,
  getOrgInfo: orgActions.getOrgInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPanel);
