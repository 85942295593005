import React, { useState, useEffect } from 'react';
import { Button, Panel, Icon } from '@holokit/core';
import { Link } from 'react-router';
import { getAllReleaseFlags, removeReleaseFlagFromPublic, makeReleaseFlagPublic } from 'common/api';

import * as styles from './styles.module.scss';

function ReleaseFlags() {
  const [allReleaseFlags, setAllReleaseFlags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllReleaseFlags().then((res) => {
      const flags = Object.keys(res?.data?.data ?? {});
      const labeledFlags = [];
      flags
        .filter((flag) => flag !== '' && !flag.startsWith('bmp_'))
        .forEach((flag) => {
          labeledFlags.push({
            name: flag,
            isPublic: Number(res.data.data[flag].public) === 1,
            organizations: res.data.data[flag].organizations || [],
          });
        });
      setAllReleaseFlags(labeledFlags);
      setIsLoading(false);
    });
  }, []);

  const publicFlags = [];
  const privateFlags = [];

  allReleaseFlags.forEach((flag) => {
    if (flag.isPublic) {
      publicFlags.push(flag);
    } else {
      privateFlags.push(flag);
    }
  });

  const setNewFlags = (flagName, isPublic) => {
    const newFlags = allReleaseFlags.map((flag) => {
      if (flag.name === flagName && isPublic) {
        return { ...flag, isPublic: false };
      }
      if (flag.name === flagName) {
        return { ...flag, isPublic: false };
      }
      return flag;
    });
    setAllReleaseFlags(newFlags);
  };

  const handleClick = (flagName, isPublic) => {
    if (isPublic) {
      removeReleaseFlagFromPublic(flagName).then(() => {
        setNewFlags(flagName, isPublic);
      });
    } else {
      makeReleaseFlagPublic(flagName).then(() => {
        setNewFlags(flagName, isPublic);
      });
    }
  };

  return (
    <Panel title="Release Flags" classes={styles.releaseFlags} isLoading={isLoading}>
      <div className={styles.container}>
        <div>
          <h2>Global Flags</h2>
          <ul className={styles.list}>
            {publicFlags.map((flag) => (
              <li className={styles.item} key={flag.name}>
                <span className={styles.name}>{flag.name}</span>
                <Button
                  type="secondary"
                  onClick={() => handleClick(flag.name, flag.isPublic)}
                  small
                >
                  Make private
                </Button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2>Private Flags</h2>
          <ul className={styles.list}>
            {privateFlags.map((flag) => (
              <li className={styles.item} key={flag.name}>
                <Link to={`/workspace/releaseflags/${flag.name}`}>
                  <div className={styles.link}>
                    {flag.name}
                    <Icon name="Arrow--northeast" />
                  </div>
                </Link>
                <Button
                  type="secondary"
                  onClick={() => handleClick(flag.name, flag.isPublic)}
                  small
                >
                  Make global
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Panel>
  );
}

export default ReleaseFlags;
