// extracted by mini-css-extract-plugin
var _1 = "form__container--UnlWWbJ3";
var _2 = "form__item--jSIhpB2u";
var _3 = "form__results--rUj779sI";
var _4 = "form__results__item--K4rF2CDM";
var _5 = "panelItem--zvlLksgh";
var _6 = "panel__container--CGxjotje";
var _7 = "panel__item--n_4nT1Z8";
var _8 = "subHeading--GK_i55v_";
export { _1 as "form__container", _2 as "form__item", _3 as "form__results", _4 as "form__results__item", _5 as "panelItem", _6 as "panel__container", _7 as "panel__item", _8 as "subHeading" }
