import * as actionTypes from './actionTypes';

export const createPlan = (groupData, orgId, planData, tiers) => ({
  type: actionTypes.CREATE_PLAN,
  groupData,
  orgId,
  planData,
  tiers,
});

export const updatePlan = (planId, planName, planData, region, zone, displayCategory) => {
  const params = {};
  if (planName) params.name = planName;
  if (planData) params.data = planData;
  if (region) params.cellular_coverage_region_id = region;
  if (displayCategory) params.display_category = displayCategory;
  if (zone) params.zones = zone;
  return {
    type: actionTypes.UPDATE_PLAN_REQUEST,
    requestType: 'PUT',
    successType: actionTypes.UPDATE_PLAN_SUCCESS,
    errorType: actionTypes.UPDATE_PLAN_ERROR,
    payload: params,
    url: `/plans/${planId}`,
  };
};

export const persistPlan = (planData) => ({
  type: actionTypes.PERSIST_PLAN_REQUEST,
  requestType: 'POST',
  successType: actionTypes.PERSIST_PLAN_SUCCESS,
  errorType: actionTypes.PERSIST_PLAN_ERROR,
  payload: planData,
  url: '/plans',
});

export const getOrgsPlanInfo = (orgId) => ({
  type: actionTypes.GET_ORG_PLANS_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_ORG_PLANS_SUCCESS,
  errorType: actionTypes.GET_ORG_PLANS_ERROR,
  url: `/plans?orgid=${orgId}`,
  passthroughData: { orgId },
});

export const searchPlans = (orgId = null, planName = null, planId = null) => {
  const params = new URLSearchParams({ includecsp: 1 });
  if (planId) {
    params.set('id', planId);
  } else if (orgId) {
    params.set('orgid', orgId);
  } else {
    params.set('name', planName);
  }

  const url = `/plans?${params.toString()}`;

  return {
    type: actionTypes.SEARCH_PLANS_REQUEST,
    requestType: 'GET',
    successType: actionTypes.SEARCH_PLANS_SUCCESS,
    errorType: actionTypes.SEARCH_PLANS_ERROR,
    url,
  };
};

export const getPlan = (planId) => ({
  type: actionTypes.GET_PLAN_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_PLAN_SUCCESS,
  errorType: actionTypes.GET_PLAN_ERROR,
  url: `/plans/${planId}?includecsp=1`,
});

export const getRegions = () => ({
  type: actionTypes.GET_REGIONS_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_REGIONS_SUCCESS,
  errorType: actionTypes.GET_REGIONS_ERROR,
  url: `/plans/regions`,
});
