const predeploymentFlags = [
  {
    label: 'preflight',
    flags: ['preflight'],
  },
  {
    label: 'preflight_api',
    flags: ['preflight_api'],
  },
  {
    label: 'preflight_tab',
    flags: ['preflight_tab'],
  },
];

const homeFlags = [
  {
    label: 'fleet',
    flags: ['fleet'],
  },
];

export default {
  predeployment: predeploymentFlags,
  home: homeFlags,
};
