import * as actionTypes from './actionTypes';

export const getCellularLink = (linkId) => ({
  type: actionTypes.GET_LINK_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_LINK_SUCCESS,
  errorType: actionTypes.GET_LINK_ERROR,
  url: `links/cellular/${linkId}`,
});

// SIM === ICCID
export const getLinkByIccid = (iccid) => ({
  type: actionTypes.GET_LINK_BY_ICCID_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_LINK_BY_ICCID_SUCCESS,
  errorType: actionTypes.GET_LINK_BY_ICCID_ERROR,
  url: `links/cellular?sim=${iccid}`,
});

export const getLinkByImsi = (imsi) => ({
  type: actionTypes.GET_LINK_BY_IMSI_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_LINK_BY_IMSI_SUCCESS,
  errorType: actionTypes.GET_LINK_BY_IMSI_ERROR,
  url: `links/cellular?imsi=${imsi}`,
});
