import axios from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as userActions from 'common/store/user/actions';

export const requestInstance = axios.create({
  baseURL: API_URL,
});

const isGetRequest = (action) => action.requestType === 'GET';
const isPostRequest = (action) => action.requestType === 'POST';
const isPutRequest = (action) => action.requestType === 'PUT';
const isDeleteRequest = (action) => action.requestType === 'DELETE';

function* getRequest(action) {
  const requestConfig = {
    method: 'GET',
    url: action.url,
    withCredentials: true,
  };
  const resp = yield call(request, requestConfig);
  if (resp.isError) {
    if (action.errorType) {
      yield put({
        type: action.errorType,
        originType: action.type,
        response: resp.error,
        ...action.passthroughData,
      });
    }
  } else {
    yield put({
      type: action.successType,
      originType: action.type,
      response: resp.data,
      ...action.passthroughData,
    });
  }
}

function* postRequest(action) {
  const requestConfig = {
    data: action.payload,
    method: 'POST',
    url: action.url,
    withCredentials: true,
    ...action.requestOptions,
  };
  const resp = yield call(request, requestConfig);
  if (resp.isError) {
    if (action.errorType) {
      yield put({
        type: action.errorType,
        originType: action.type,
        response: resp.error,
        ...action.passthroughData,
      });
    }
  } else {
    yield put({
      type: action.successType,
      originType: action.type,
      response: resp.data,
      ...action.passthroughData,
    });
  }
}

function* putRequest(action) {
  const requestConfig = {
    data: action.payload,
    method: 'PUT',
    url: action.url,
    ...action.requestOptions,
    withCredentials: true,
  };
  const resp = yield call(request, requestConfig);
  if (resp.isError) {
    if (action.errorType) {
      yield put({
        type: action.errorType,
        originType: action.type,
        response: resp.error,
        ...action.passthroughData,
      });
    }
  } else {
    yield put({
      type: action.successType,
      originType: action.type,
      response: resp.data,
      ...action.passthroughData,
    });
  }
}

function* deleteRequest(action) {
  const requestConfig = {
    data: action.payload,
    method: 'DELETE',
    url: action.url,
    withCredentials: true,
    ...action.requestOptions,
  };
  const resp = yield call(request, requestConfig);
  if (resp.isError) {
    if (action.errorType) {
      yield put({
        type: action.errorType,
        originType: action.type,
        response: resp.error,
        ...action.passthroughData,
      });
    }
  } else {
    yield put({
      type: action.successType,
      originType: action.type,
      response: resp.data,
      ...action.passthroughData,
    });
  }
}

export function* request(requestConfig) {
  try {
    const response = yield call(requestInstance, requestConfig);
    return response;
  } catch (error) {
    if (error.response?.status === 403) {
      yield put(userActions.forceToLoginScreen());
    }
    if (error?.response?.data?.error) {
      return {
        isError: true,
        error: error?.response?.data?.error,
      };
    }
    return {
      isError: true,
      error: error.message,
    };
  }
}

export default function* rootRequestSaga() {
  yield all([
    takeEvery(isGetRequest, getRequest),
    takeEvery(isPostRequest, postRequest),
    takeEvery(isPutRequest, putRequest),
    takeEvery(isDeleteRequest, deleteRequest),
  ]);
}
