const PREFIX = 'LINKS_';

export const GET_LINK_REQUEST = `${PREFIX}GET_LINK_REQUEST`;
export const GET_LINK_SUCCESS = `${PREFIX}GET_LINK_SUCCESS`;
export const GET_LINK_ERROR = `${PREFIX}GET_LINK_ERROR`;

export const GET_LINK_BY_ICCID_REQUEST = `${PREFIX}GET_LINK_BY_ICCID_REQUEST`;
export const GET_LINK_BY_ICCID_SUCCESS = `${PREFIX}GET_LINK_BY_ICCID_SUCCESS`;
export const GET_LINK_BY_ICCID_ERROR = `${PREFIX}GET_LINK_BY_ICCID_ERROR`;

export const GET_LINK_BY_IMSI_REQUEST = `${PREFIX}GET_LINK_BY_IMSI_REQUEST`;
export const GET_LINK_BY_IMSI_SUCCESS = `${PREFIX}GET_LINK_BY_IMSI_SUCCESS`;
export const GET_LINK_BY_IMSI_ERROR = `${PREFIX}GET_LINK_BY_IMSI_ERROR`;
