import Confirmation from './ConfirmationModal';
import CreatePlanConfirmation from './CreatePlanConfirmation';
import EnableOrChangeOrgPlan from './EnableOrChangeOrgPlan';
import EnablePostPay from './EnablePostPay';
import SuccessNotice from './SuccessNotice';
import AddBalance from './AddBalanceModal';
import MfaLoginModal from './MfaLoginModal';

const ModalInstances = {
  confirmation: Confirmation,
  createPlanConfirmation: CreatePlanConfirmation,
  enableOrChangeOrgPlan: EnableOrChangeOrgPlan,
  enablePostPay: EnablePostPay,
  successNotice: SuccessNotice,
  addBalance: AddBalance,
  mfaLoginModal: MfaLoginModal,
};

export default ModalInstances;
export const modalNames = Object.keys(ModalInstances).reduce(
  (acc, modalName) => ({
    ...acc,
    [modalName]: modalName,
  }),
  {}
);
