import { omit } from 'lodash';

import * as devicesActionTypes from 'common/store/entities/devices/actionTypes';
import * as linksActionTypes from 'common/store/entities/links/actionTypes';
import * as userActionTypes from 'common/store/entities/users/actionTypes';
import * as orgsActionTypes from 'common/store/entities/orgs/actionTypes';
import * as modalActionTypes from 'common/store/ui/modals/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {};

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.THROW_DEVICE_ERROR: {
      return {
        ...state,
        searchDevice: {
          error: action.error,
        },
      };
    }

    case actionTypes.THROW_ORG_ERROR: {
      return {
        ...state,
        searchOrg: {
          error: action.error,
        },
      };
    }

    case actionTypes.THROW_POSTPAY_ERROR: {
      return {
        ...state,
        postPay: {
          error: action.error,
        },
      };
    }

    case actionTypes.CLEAR_ORG_SEARCH_STATE:
    case userActionTypes.GET_ORGS_BY_USER_EMAIL:
    case orgsActionTypes.SEARCH_ORG_REQUEST: {
      return omit(state, 'searchOrg');
    }

    case actionTypes.CLEAR_DEVICE_SEARCH_STATE:
    case devicesActionTypes.GET_DEVICE_REQUEST:
    case devicesActionTypes.GET_DEVICE_BY_IMEI_REQUEST:
    case linksActionTypes.GET_LINK_REQUEST:
    case linksActionTypes.GET_LINK_BY_ICCID_REQUEST:
    case linksActionTypes.GET_LINK_BY_IMSI_REQUEST: {
      return omit(state, 'searchDevice');
    }

    case orgsActionTypes.ENABLE_POSTPAY:
    case modalActionTypes.CLOSE: {
      return omit(state, 'postPay');
    }

    default:
      return state;
  }
}
