import * as actionTypes from './actionTypes';

const initialState = {
  user: {
    email: null,
  },
  loginFailed: null,
  userIsAuthed: false,
  shouldLogout: false,
  mfa: {
    flow: false,
    showModal: false,
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: {
      const { email } = action.response.data;
      return {
        ...state,
        user: { email },
        loginFailed: false,
      };
    }

    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginFailed: true,
      };

    case actionTypes.LOGIN_REQUEST: {
      const { email, rememberMe } = action.payload;
      return {
        ...state,
        loginFailed: false,
        shouldLogout: false,
        user: {
          email,
          rememberMe,
        },
      };
    }

    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.FORCE_LOGIN: {
      return {
        ...initialState,
        shouldLogout: true,
      };
    }

    case actionTypes.GET_USER_INFO_SUCCESS: {
      const { data } = action.response;
      return {
        ...state,
        user: data,
        userIsAuthed: true,
        mfa: {
          ...state.mfa,
          isLoading: false,
          flow: false,
          showModal: false,
        },
      };
    }
    case actionTypes.MFA_FLOW: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          flow: action.payload.flow,
        },
      };
    }
    case actionTypes.SAVE_MFA_METHOD: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          ...action.payload.method,
        },
      };
    }
    case actionTypes.SET_MFA_MODAL: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          showModal: action.payload.showModal,
        },
      };
    }
    case actionTypes.HANDLE_MFA_ERROR: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          isLoading: false,
          error: action.response,
        },
      };
    }
    case actionTypes.START_MFA_AUTHENTICATION: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          isLoading: true,
        },
      };
    }
    case actionTypes.CLEAR_MFA_ERRORS: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          error: false,
        },
      };
    }

    case actionTypes.RESET_MFA_FLOW: {
      return {
        ...state,
        mfa: {
          flow: false,
          showModal: false,
        },
      };
    }

    default:
      return state;
  }
}
