import { all, put, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from 'common/store/user/actionTypes';
import * as actions from 'common/store/user/actions';

const getUserInfo = (state) => state.user.user;
const getUserMfaInfo = (state) => state.user.mfa;

function* loginSuccess(action) {
  const { role } = action.response.data;

  const adminRoles = ['adm', 'hdm'];
  if (!adminRoles.includes(role)) {
    yield put(actions.logout());
  }

  yield put(actions.getUserInfo());
}

function* startMfaFlow(userInfo) {
  yield put(actions.setMfaFlow(true));
  yield put(actions.saveMfaMethod(userInfo));
  yield put(actions.setMfaModal(true));
}

function* handleAuthFlow(action) {
  const { data } = action.response;
  const { email, rememberMe } = yield select(getUserInfo);
  const userInfo = {
    email,
    rememberMe,
    ...data,
  };

  if (data?.mfa) {
    yield startMfaFlow(userInfo);
  } else {
    yield loginSuccess(action);
  }
}

function* authenticateTotp(action) {
  yield put(actions.clearMfaErrors());
  yield put(actions.startAuthentication());
  const { totpCode } = action.payload;
  const { email } = yield select(getUserInfo);
  const { email_id: methodId } = yield select(getUserMfaInfo);
  const payload = {
    email,
    methodId,
    totpCode,
  };

  yield put({
    type: actionTypes.AUTHENTICATE_TOTP_REQUEST,
    requestType: 'POST',
    url: '/auth/totp/authenticate',
    successType: actionTypes.LOGIN_SUCCESS,
    errorType: actionTypes.HANDLE_MFA_ERROR,
    payload,
  });
}

function* resendTotp() {
  const { email } = yield select(getUserInfo);

  yield put({
    type: actionTypes.RESEND_TOTP_REQUEST,
    requestType: 'POST',
    url: '/auth/totp/resend',
    successType: actionTypes.RESEND_TOTP_REQUEST_SUCCESS,
    errorType: actionTypes.HANDLE_MFA_ERROR,
    payload: { email },
  });
}

function handleLogOutSuccess() {}

function* rootUserSaga() {
  yield all([
    takeEvery(actionTypes.RESEND_TOTP, resendTotp),
    takeEvery(actionTypes.AUTHENTICATE_TOTP, authenticateTotp),
    takeEvery(actionTypes.LOGIN_SUCCESS, loginSuccess),
    takeEvery(actionTypes.HANDLE_AUTH_FLOW, handleAuthFlow),
    takeEvery(actionTypes.LOGOUT_SUCCESS, handleLogOutSuccess),
  ]);
}

export default rootUserSaga;
