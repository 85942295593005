import { headers } from './config';

const axios = require('axios');

export function getAllReleaseFlags() {
  return axios({
    method: 'get',
    url: `${API_URL}/releaseflags?showAll=true`,
    withCredentials: true,
    headers,
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function updateReleaseFlagForOrg(orgid, releaseFlagName, removeFlag) {
  return axios({
    method: 'put',
    url: `${API_URL}/releaseflags/${orgid}`,
    withCredentials: true,
    headers,
    data: JSON.stringify({
      name: releaseFlagName,
      state: removeFlag ? 'off' : 'on',
    }),
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function removeReleaseFlagFromPublic(flagName) {
  return axios({
    method: 'delete',
    url: `${API_URL}/releaseflags/public`,
    withCredentials: true,
    headers,
    data: JSON.stringify({
      name: flagName,
    }),
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function makeReleaseFlagPublic(flagName) {
  return axios({
    method: 'post',
    url: `${API_URL}/releaseflags/public`,
    withCredentials: true,
    headers,
    data: JSON.stringify({
      name: flagName,
    }),
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function getUser(userid) {
  return axios({
    method: 'get',
    url: `${API_URL}/users/${userid}`,
    withCredentials: true,
    headers,
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}

export function updateUserGlobalRole(userid, role) {
  return axios({
    method: 'put',
    url: `${API_URL}/users/${userid}`,
    withCredentials: true,
    headers,
    data: JSON.stringify({
      role,
    }),
  })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
}
