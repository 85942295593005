import * as actionTypes from './actionTypes';

export const createPlanTier = (planId, tierData) => ({
  type: actionTypes.CREATE_PLAN_TIER_REQUEST,
  requestType: 'POST',
  successType: actionTypes.CREATE_PLAN_TIER_SUCCESS,
  errorType: actionTypes.CREATE_PLAN_TIER_ERROR,
  payload: tierData,
  url: `/plans/${planId}/tiers`,
});

export const updatePlanTiers = (planId, newTiers, oldTiers) => ({
  type: actionTypes.EDIT_TIERS,
  planId,
  newTiers,
  oldTiers,
});

export const persistTierUpdate = (planId, tierNumber, tierData) => ({
  type: actionTypes.PERSIST_TIER_UPDATE_REQUEST,
  requestType: 'PUT',
  successType: actionTypes.PERSIST_TIER_UPDATE_SUCCESS,
  errorType: actionTypes.PERSIST_TIER_UPDATE_ERROR,
  payload: tierData,
  url: `/plans/${planId}/tiers/${tierNumber}DV`,
});

export const deleteTier = (planId, tierNumber) => ({
  type: actionTypes.DELETE_TIER_REQUEST,
  requestType: 'DELETE',
  successType: actionTypes.DELETE_TIER_SUCCESS,
  errorType: actionTypes.DELETE_TIER_ERROR,
  url: `/plans/${planId}/tiers/${tierNumber}DV`,
});

export const getTierVolumeTypes = () => ({
  type: actionTypes.GET_TIER_VOLUME_TYPES_REQUEST,
  requestType: 'GET',
  successType: actionTypes.GET_TIER_VOLUME_TYPES_SUCCESS,
  errorType: actionTypes.GET_TIER_VOLUME_TYPES_ERROR,
  url: '/organizations/tiers',
});

export const createTierType = (volume, name, description) => ({
  type: actionTypes.CREATE_TIER_VOLUME_TYPE_REQUEST,
  requestType: 'POST',
  successType: actionTypes.CREATE_TIER_VOLUME_TYPE_SUCCESS,
  errorType: actionTypes.CREATE_TIER_VOLUME_TYPE_ERROR,
  url: '/organizations/tiers',
  payload: {
    name,
    description,
    gate_devicecount: volume,
    gate_datausage: 0,
  },
  passthroughData: { volume },
});
