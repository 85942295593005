const PREFIX = 'PLANS_';

export const PERSIST_PLAN_REQUEST = `${PREFIX}PERSIST_PLAN_REQUEST`;
export const PERSIST_PLAN_SUCCESS = `${PREFIX}PERSIST_PLAN_SUCCESS`;
export const PERSIST_PLAN_ERROR = `${PREFIX}PERSIST_PLAN_ERROR`;

export const UPDATE_PLAN_REQUEST = `${PREFIX}UPDATE_PLAN_REQUEST`;
export const UPDATE_PLAN_SUCCESS = `${PREFIX}UPDATE_PLAN_SUCCESS`;
export const UPDATE_PLAN_ERROR = `${PREFIX}UPDATE_PLAN_ERROR`;

export const CREATE_PLAN = `${PREFIX}CREATE_PLAN`;
export const CREATE_PLAN_SUCCESS = `${PREFIX}CREATE_PLAN_SUCCESS`;
export const CREATE_OR_EDIT_PLAN_SUCCESS = `${PREFIX}CREATE__OR_EDIT_PLAN_SUCCESS`;

export const GET_ORG_PLANS_REQUEST = `${PREFIX}GET_ORG_PLANS_REQUEST`;
export const GET_ORG_PLANS_SUCCESS = `${PREFIX}GET_ORG_PLANS_SUCCESS`;
export const GET_ORG_PLANS_ERROR = `${PREFIX}GET_ORG_PLANS_ERROR`;

export const SEARCH_PLANS_REQUEST = `${PREFIX}SEARCH_PLANS_REQUEST`;
export const SEARCH_PLANS_SUCCESS = `${PREFIX}SEARCH_PLANS_SUCCESS`;
export const SEARCH_PLANS_ERROR = `${PREFIX}SEARCH_PLANS_ERROR`;

export const GET_PLAN_REQUEST = `${PREFIX}GET_PLAN_REQUEST`;
export const GET_PLAN_SUCCESS = `${PREFIX}GET_PLAN_SUCCESS`;
export const GET_PLAN_ERROR = `${PREFIX}GET_PLAN_ERROR`;

export const GET_REGIONS_REQUEST = `${PREFIX}GET_REGIONS_REQUEST`;
export const GET_REGIONS_SUCCESS = `${PREFIX}GET_REGIONS_SUCCESS`;
export const GET_REGIONS_ERROR = `${PREFIX}GET_REGIONS_ERROR`;
