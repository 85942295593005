import React from 'react';

import { Switch, Route } from 'react-router';

import AuthedMainLayout from 'components/layouts/AuthedMain';
import CreatePlan from './Create';
import SearchPlan from './Search';
import CreateOrEditPlanSuccess from './CreateOrEditPlanSuccess';
import EditPlan from './editPlan';

const PlansIndex = () => (
  <AuthedMainLayout
    navOptions={[
      {
        text: 'Search',
        pathname: '/workspace/plans/search',
        isDefault: true,
      },
      {
        text: 'Create',
        pathname: '/workspace/plans/create',
      },
    ]}
  >
    <Switch>
      <Route path="/workspace/plans/create/success" component={CreateOrEditPlanSuccess} />
      <Route path="/workspace/plans/:id/edit/success" component={CreateOrEditPlanSuccess} />
      <Route path="/workspace/plans/search" component={SearchPlan} />
      <Route path="/workspace/plans/create" component={CreatePlan} />
      <Route path="/workspace/plans/:id/edit" component={EditPlan} />
    </Switch>
  </AuthedMainLayout>
);

export default PlansIndex;
