import React from 'react';
import { Route, Switch, useParams } from 'react-router';

import ReleaseFlags from 'components/admin/ReleaseFlags';
import ReleaseFlag from 'components/admin/ReleaseFlag';
import AuthedMainLayout from 'components/layouts/AuthedMain';

const AdminReleaseFlags = () => {
  const params = useParams();

  const navOptions = [
    {
      text: 'View All',
      pathname: '/workspace/releaseflags',
      isDefault: true,
    },
  ];

  if (params.flagName) {
    navOptions.push({
      text: params.flagName,
      pathname: `/workspace/releaseflags/${params.flagName}`,
    });
  }

  return (
    <AuthedMainLayout
      navOptions={[
        {
          text: 'View All',
          pathname: '/workspace/releaseflags',
          isDefault: true,
        },
      ]}
    >
      <Switch>
        <Route exact path="/workspace/releaseflags" component={ReleaseFlags} />
        <Route path="/workspace/releaseflags/:flagName" component={ReleaseFlag} />
      </Switch>
    </AuthedMainLayout>
  );
};

export default AdminReleaseFlags;
