import { Button, FormGroup, InputGroup } from '@holokit/core';
import { useFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';

import * as userActions from 'common/store/user/actions';

const LoginForm = ({ login }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      login(values.email, values.password);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGroup label="Email">
        <InputGroup
          name="email"
          id="email"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.email}
          required
        />
      </FormGroup>
      <FormGroup label="Password">
        <InputGroup
          name="password"
          type="password"
          id="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          required
        />
      </FormGroup>
      <FormGroup>
        <Button type="primary" fullWidth buttonProps={{ type: 'submit' }}>
          Log In
        </Button>
      </FormGroup>
    </form>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(userActions.login(email, password, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
