import { Form } from '@hologram-dimension/form';
import { Panel } from '@hologram-dimension/panel';
import { RadioField } from '@hologram-dimension/radio-field';
import { SelectField } from '@hologram-dimension/select-field';
import { TextInputField } from '@hologram-dimension/text-input-field';
import ErrorBoundary from 'components/common/ErrorBoundary';
import CarrierForm from 'components/plans/PlanPanel/CarrierForm/index';
import DetailsPanel from 'components/plans/PlanPanel/DetailsPanel/index';
import HelpPanel from 'components/plans/PlanPanel/HelpPanel';
import ZoneForm from 'components/plans/PlanPanel/ZoneForm/index';
import React from 'react';
import * as styles from './PlanPanel.module.scss';

class PlanPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelp: false,
    };
  }

  handlePlanChange = (e) => {
    const { onUpdatePlan } = this.props;
    onUpdatePlan(e.target.name, e.target.value);
  };

  handlePlanChangeNumberOnly = (e) => {
    const { onUpdatePlan } = this.props;
    // regex to only allow whole numbers
    const expression = /^[0-9]\d*$/;
    const { name, value } = e.target;

    if (expression.test(value) || value === '') {
      onUpdatePlan(name, value);
    }
  };

  handleRegionChange = (e) => {
    const { onUpdatePlan } = this.props;
    onUpdatePlan('region', e.target.value);
  };

  toggleHelp = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      showHelp: value === 'showHelp',
    }));
  };

  render() {
    const {
      orgId,
      orgName,
      planData,
      group,
      onUpdateZone,
      isEditPage,
      editedZone,
      onUpdateCSP,
      regions,
    } = this.props;
    const { showHelp } = this.state;

    const REGION_OPTIONS = regions
      ? [
          {
            value: '0',
            label: 'None',
          },
          ...regions
            .map((region) => ({
              value: region.id.toString(),
              label: region.display_name,
            }))
            .reverse(),
        ]
      : [''];

    return (
      <ErrorBoundary>
        <Panel header={orgId ? `Add Plan to ${orgName} (${orgId})` : 'Plans'}>
          <div className={styles.panel__container}>
            <div className={styles.panelItem}>
              <Form>
                <h2 className={styles.subHeading}>Plan</h2>
                <TextInputField
                  type="text"
                  label="Plan name"
                  placeholder="name"
                  name="name"
                  defaultValue={planData.name}
                  onChange={this.handlePlanChange}
                  required
                />
                <TextInputField
                  type="number"
                  label="Included data (bytes)"
                  placeholder="Data"
                  name="data"
                  defaultValue={planData.data}
                  onChange={this.handlePlanChangeNumberOnly}
                  required
                />
                <h2 className={styles.subHeading}>Plan configuration</h2>
                <SelectField
                  label="Data plan type"
                  helpText="Is this a Standard data plan providing Website pricing? Data plans created for a managed account MSA should always be Custom."
                  name="display_category"
                  defaultValue={planData.display_category}
                  onChange={this.handlePlanChange}
                  options={[
                    { value: 'custom', label: 'Custom data plan' },
                    { value: 'standard', label: 'Standard/Default data plan' },
                  ]}
                  required
                />
                <SelectField
                  label="Region"
                  helpText="Groups Standard Website data plans in the Activation and Change Plan workflows."
                  name="region"
                  defaultValue={planData.region}
                  onChange={this.handleRegionChange}
                  options={REGION_OPTIONS}
                  required={planData.display_category === 'standard'}
                />
              </Form>
            </div>
            <div className={styles.panelItem}>
              <h2 className={styles.subHeading}>Zone</h2>
              <ZoneForm zone={planData.zones} onUpdateZone={onUpdateZone} isEditPage={isEditPage} />
            </div>
            <div className={styles.panelItem}>
              <h2 className={styles.subHeading}>Carrier</h2>
              <CarrierForm
                carriers={planData.zones.carriers}
                isEditPage={isEditPage}
                updateZone={onUpdateZone}
                editedZone={editedZone}
                onUpdateCSP={onUpdateCSP}
              />
            </div>
            <div className={styles.panelItem}>
              {!isEditPage && (
                <RadioField
                  name="showPlan"
                  defaultValue="showPlan"
                  options={[
                    { label: 'Show plan', value: 'showPlan', defaultChecked: true },
                    { label: 'Show help', value: 'showHelp' },
                  ]}
                  onChange={this.toggleHelp}
                  controlsLayoutDirection="row"
                />
              )}
              {showHelp || isEditPage ? (
                <HelpPanel />
              ) : (
                <DetailsPanel orgName={orgName} orgId={orgId} planData={planData} group={group} />
              )}
            </div>
          </div>
        </Panel>
      </ErrorBoundary>
    );
  }
}

export default PlanPanel;
