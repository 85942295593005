import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Callout } from '@holokit/core';

const useCallouts = () => {
  const [callouts, setCallouts] = useState([]);

  const addSuccessCallouts = useCallback(
    (labels) => {
      setCallouts((rest) => [
        ...rest,
        ...labels.map((label) => ({
          calloutType: Callout.TYPE.SUCCESS,
          text: label,
          uuid: uuidv4(),
        })),
      ]);
    },
    [setCallouts]
  );

  const addErrorCallouts = useCallback(
    (labels) => {
      setCallouts((rest) => [
        ...rest,
        ...labels.map((label) => ({
          calloutType: Callout.TYPE.ERROR,
          text: label,
          uuid: uuidv4(),
        })),
      ]);
    },
    [setCallouts]
  );

  const removeCallout = (uuid) =>
    setCallouts((rest) => rest.filter((callout) => uuid !== callout.uuid));

  return { callouts, addSuccessCallouts, addErrorCallouts, removeCallout };
};

export default useCallouts;
