import { all } from 'redux-saga/effects';

import orgsRootSaga from './orgs';
import plansRootSaga from './plans';
import usersRootSaga from './users';
import devicesRootSaga from './devices';

function* entitiesRootSaga() {
  yield all([orgsRootSaga(), plansRootSaga(), usersRootSaga(), devicesRootSaga()]);
}

export default entitiesRootSaga;
