import * as actionTypes from './actionTypes';

export const clearPlanState = () => ({
  type: actionTypes.CLEAR_PLAN_CREATE_STATE,
});

export const throwError = (error) => ({
  type: actionTypes.THROW_ERROR,
  error,
});
