import { connect } from 'react-redux';
import _classnames from 'classnames';
import { DeepMenu, DeepMenuItem } from '@holokit/core';
import React, { useMemo } from 'react';
import { useLocation, Link } from 'react-router';

import * as userActions from 'common/store/user/actions';

import * as styles from './styles.module.scss';

const Nav = ({ user, logout, options }) => {
  const location = useLocation();

  const displayOptions = useMemo(() => {
    const selectedOptionIndex = options?.findIndex((opt) => {
      const isSelected =
        location.search.includes(opt.searchType) || location.pathname.includes(opt.pathname);
      return !opt.isDefault && isSelected;
    });
    const noSelection = selectedOptionIndex === -1;

    return options?.map((opt, index) => (
      <Link
        className={_classnames(styles.item, {
          [styles.itemActive]: selectedOptionIndex === index || (noSelection && opt.isDefault),
        })}
        key={opt.pathname}
        to={opt.pathname}
      >
        {opt.text}
      </Link>
    ));
  }, [location]);

  return (
    <DeepMenu className={styles.deepMenu}>
      <div className={styles.leftContainer}>{displayOptions}</div>
      {user && <DeepMenuItem text="Sign out" onClick={logout} />}
    </DeepMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user.email,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
