import update from 'immutability-helper';
import { toObjectByIds, toBooleanObjectByIds } from 'src/common/utils/objectFormatter/index';
import * as actionTypes from './actionTypes';

const initialState = {
  byId: {},
  loaded: {},
};

export default function linksReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LINK_SUCCESS: {
      const { data } = action.response;

      const formattedLink = toObjectByIds(data);
      const link = toBooleanObjectByIds(data);

      return update(state, {
        byId: { $merge: formattedLink },
        loaded: { $merge: link },
      });
    }

    case actionTypes.GET_LINK_BY_ICCID_SUCCESS: {
      const { data } = action.response;

      const formattedLinks = toObjectByIds(data);
      const links = toBooleanObjectByIds(data);

      return update(state, {
        byId: { $merge: formattedLinks },
        loaded: { $merge: links },
      });
    }

    case actionTypes.GET_LINK_BY_IMSI_SUCCESS: {
      const { data } = action.response;

      const formattedLinks = toObjectByIds(data);
      const links = toBooleanObjectByIds(data);

      return update(state, {
        byId: { $merge: formattedLinks },
        loaded: { $merge: links },
      });
    }

    default:
      return state;
  }
}
