import * as actionTypes from './actionTypes';

export const openModal = (modalName, modalProps = {}) => ({
  type: actionTypes.OPEN,
  openModalName: modalName,
  modalProps,
});

export const closeModal = () => ({
  type: actionTypes.CLOSE,
});
